import { schema } from 'normalizr';
// Course Details
export var video = new schema.Entity('videos');
export var resource = new schema.Entity('resources');
export var note = new schema.Entity('notes');
export var chapter = new schema.Entity('chapters', {
    videos: [video],
    resources: [resource],
    notes: [note],
});
export var assignment = new schema.Entity('assignments');
export var unit = new schema.Entity('unitDetails');
export var courses = new schema.Entity('courses');
export var modules = new schema.Entity('modules', {
    units: [unit],
});
export var applications = new schema.Entity('applications');
export var batches = new schema.Entity('batches', {}, { idAttribute: 'batchId' });
// Course Progress Tracking
export var chapterTrack = new schema.Entity('chapterTracks');
export var assignmentTrack = new schema.Entity('assignmentTracks');
export var quizTrack = new schema.Entity('quizTracks');
export var videoTrack = new schema.Entity('videoTracks');
export var moduleTrack = new schema.Entity('modulesTracks');
export var courseTrack = new schema.Entity('courseTracks');
export var projectTrack = new schema.Entity('projectTracks');
export var chapterVideoTracks = new schema.Entity('chapterVideoTracks', {}, { idAttribute: 'chapterId' });
export var unitTrack = new schema.Entity('unitTracks', {
    chapterTracks: [chapterTrack],
    assignmentTracks: [assignmentTrack],
});
export var program = new schema.Entity('programs');
export var courseSyllabus = new schema.Entity('courses', {}, { idAttribute: 'courseId' });
export var moduleSyllabus = new schema.Entity('modules', {}, { idAttribute: 'moduleId' });
export var chapterSyllabus = new schema.Entity('chapters', {}, { idAttribute: 'chapterId' });
// Like or Dislike Video Analytics
export var videoLikeDislike = new schema.Entity('videoLikeDislike', {}, { idAttribute: 'videoId' });
