import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { PostStateType } from 'src/scenes/myclassroom/Forum/reducers/post/post.d';
import post from 'src/scenes/myclassroom/Forum/reducers/post';
import homeRegistry, { HomeRegistryStateType } from '@fuse/cognito/dist/Reducer/HomeRegistry';
import session, { SessionStoreStateType } from '@fuse/cognito/dist/Reducer/Session';
import { ForumReducer } from 'src/scenes/myclassroom/Forum/reducers';
import { FORUM_PREFERENCE_REDUCERS } from 'src/scenes/myclassroom/Forum/reducers/forum/forum';

export interface RootState {
  session: SessionStoreStateType;
  homeRegistry: HomeRegistryStateType;
  post: PostStateType;
  ForumReducer: FORUM_PREFERENCE_REDUCERS;
}

export default combineReducers({
  session,
  homeRegistry,
  router: routerReducer,
  post,
  ForumReducer,
});
