import Amplify, { Auth, Hub } from 'aws-amplify';
var cognitoConfig = {
    cognitoProjectRegion: process.env.REACT_APP_COGNITO_PROJECT_REGION,
    cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
    cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
};
Amplify.configure({
    aws_project_region: cognitoConfig.cognitoProjectRegion,
    aws_cognito_region: cognitoConfig.cognitoRegion,
    aws_user_pools_id: cognitoConfig.cognitoPoolId,
    aws_user_pools_web_client_id: cognitoConfig.cognitoClientId,
    oauth: {
        domain: cognitoConfig.cognitoDomain,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: "" + window.location.origin,
        redirectSignOut: window.location.origin + "/logout",
        responseType: 'code',
    },
});
export { Auth as CognitoAuth, Hub as CognitoHub };
