var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var ZOOM_LINK_REGEX = 'https://(.*.)?zoom.us/[a-z]/.*';
export var GOOGLE_MEET_LINK_REGEX = 'https://meet.google.com/.*';
export var isUrl = function (str) {
    if (str) {
        var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
};
// ***************************** Validates Zoom meeting link ****************************
export var isValidZoomUrl = function (value) {
    if (value.length) {
        var zoomRegex = new RegExp(ZOOM_LINK_REGEX);
        if (zoomRegex.test(value)) {
            return undefined;
        }
        return 'Enter a valid zoom url.';
    }
    return undefined;
};
// ***************************** Validates Google meeting link ****************************
export var isValidGoogleMeetUrl = function (value) {
    if (value.length) {
        var googleMeetRegex = new RegExp(GOOGLE_MEET_LINK_REGEX);
        if (googleMeetRegex.test(value)) {
            return undefined;
        }
        return 'Enter a valid google meet url.';
    }
    return undefined;
};
export var getQueryParams = function (data) {
    var queryParam = '';
    for (var key in data) {
        var tempData = data;
        if (tempData.hasOwnProperty(key)) {
            queryParam = "" + queryParam + key + "=" + tempData[key] + "&";
        }
    }
    return queryParam;
};
/**
 *
 * @param query old query params from url
 * @param newParams new query params from url
 * @returns unique query params who's value is not undefined, empty string and zero
 * @example { academicYearId: '5f1562cda8ba420042f5831f', courseId: undefined, page: 0 } => { academicYearId: '5f1562cda8ba420042f5831f'}
 */
export var getNewQueryParams = function (query, newParams) {
    var tempParams = __assign(__assign({}, query), newParams);
    return Object.fromEntries(Object.entries(tempParams).filter(function (_a) {
        var _tempParam = _a[1];
        return _tempParam;
    }));
};
export var addQueryParams = function (queryParams) {
    return "?" + queryParams;
};
