var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field } from 'react-final-form';
export var FinalArrayField = function (props) {
    var id = props.id, legend = props.legend, name = props.name, required = props.required, validate = props.validate, errorClass = props.errorClass, requirementMsg = props.requirementMsg, className = props.className, inputWrapClassName = props.inputWrapClassName, inputClassName = props.inputClassName, disabled = props.disabled, placeholder = props.placeholder, min = props.min, max = props.max, type = props.type;
    var isRequired = function (value) { return (value && value.toString().trim() ? undefined : 'Required'); };
    return (React.createElement(Field, { id: id || name, name: name, validate: validate ? validate : !required ? undefined : isRequired }, function (_a) {
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: (className ? className : 'form-group') + " " + (meta.error && (meta.dirty || meta.submitFailed) && meta.touched ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: "" },
                legend,
                (required || validate) && legend ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement("div", { className: inputWrapClassName ? inputWrapClassName : '' },
                React.createElement("input", __assign({}, input, { className: "" + (inputClassName ? inputClassName : 'form-control'), disabled: disabled ? true : false, placeholder: placeholder || '', min: min, max: max, type: type ? type : 'text' })),
                (meta.error && (meta.dirty || meta.submitFailed) && meta.touched) || requirementMsg ? (React.createElement("span", { className: errorClass && !requirementMsg ? errorClass : 'required' }, requirementMsg ? requirementMsg : meta.error)) : null)));
    }));
};
