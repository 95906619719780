var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GetStorageComponent } from '@fuse/utils';
import axios from '@fuse/config/dist/Axios';
export var asyncFunc = function (request) {
    var accessToken = GetStorageComponent('access_token');
    var idToken = GetStorageComponent('id_token');
    request.headers = {
        Authorization: 'bearer ' + accessToken,
        idToken: idToken,
    };
    return axios
        .request(request)
        .then(function (res) {
        return [null, res === null || res === void 0 ? void 0 : res.data];
    })
        .catch(function (err) {
        return [getErrorMessage(err), null];
    });
};
export var asyncPromiseFunc = function (request) {
    var accessToken = GetStorageComponent('access_token');
    var idToken = GetStorageComponent('id_token');
    if (accessToken) {
        request.headers = {
            Authorization: 'bearer ' + accessToken,
            idToken: idToken,
        };
    }
    return axios.request(request);
};
export var asyncPromiseDataFunc = function (request) {
    var accessToken = GetStorageComponent('access_token');
    var idToken = GetStorageComponent('id_token');
    if (accessToken) {
        request.headers = {
            Authorization: 'bearer ' + accessToken,
            idToken: idToken,
        };
    }
    return axios
        .request(request)
        .then(function (res) {
        return res === null || res === void 0 ? void 0 : res.data;
    })
        .catch(function (err) {
        return Promise.reject(getErrorMessage(err));
    });
};
export var unAuthorizedRequest = function (request) {
    return axios
        .request(request)
        .then(function (res) {
        return [null, res === null || res === void 0 ? void 0 : res.data];
    })
        .catch(function (err) {
        return [getErrorMessage(err), null];
    });
};
/* axiosPromiseFunction is for Fuse-ai-classroom implementation. */
export var axiosPromiseFunction = function (request) {
    var accessToken = GetStorageComponent('access_token');
    var idToken = GetStorageComponent('id_token');
    if (accessToken) {
        request.headers = {
            Authorization: 'bearer ' + accessToken,
            idToken: idToken,
        };
    }
    return new Promise(function (resolve, reject) {
        axios
            .request(request)
            .then(function (res) {
            resolve(res === null || res === void 0 ? void 0 : res.data);
        })
            .catch(function (err) {
            reject(getErrorMessage);
        });
    });
};
var CustomError = /** @class */ (function (_super) {
    __extends(CustomError, _super);
    function CustomError(_a) {
        var message = _a.message, status = _a.status, customGenerated = _a.customGenerated;
        var _this = _super.call(this, message) || this;
        _this.message = '';
        _this.status = null;
        _this.customGenerated = false;
        _this.toJSON = function () {
            return ({
                message: _this.message,
                status: _this.status,
                customGenerated: _this.customGenerated,
            });
        };
        _this.message = message;
        _this.status = status;
        _this.customGenerated = customGenerated;
        Object.setPrototypeOf(_this, CustomError.prototype); // to use instanceof to detect CustomError in components or actions
        return _this;
    }
    return CustomError;
}(Error));
export { CustomError };
export var asyncFunctionCustom = function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, idToken;
    return __generator(this, function (_a) {
        accessToken = GetStorageComponent('access_token');
        idToken = GetStorageComponent('id_token');
        if (accessToken) {
            request.headers = {
                Authorization: 'bearer ' + accessToken,
                idToken: idToken,
            };
        }
        return [2 /*return*/, axios
                .request(request)
                .then(function (res) {
                return [null, res.data]; // https://github.com/TypeStrong/typedoc/issues/1059#issuecomment-504761438
            })
                .catch(function (error) {
                var err = error ? error : null;
                return err && err.data.message && err.data.message
                    ? [
                        new CustomError({ message: err.data.message, status: error.data.status || error.status }),
                        null,
                    ]
                    : err && err.message
                        ? [
                            new CustomError({ message: err.message.message, status: err.statusCode || err.statusCode }),
                            null,
                        ]
                        : [new CustomError({ message: 'Please Try Again', customGenerated: true }), null];
            })];
    });
}); };
export var getErrorMessage = function (error) {
    var _a, _b, _c, _d, _e;
    return ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) ? error.response.data.message
        : ((_c = error === null || error === void 0 ? void 0 : error.data) === null || _c === void 0 ? void 0 : _c.error_description) ? error.data.error_description
            : ((_d = error === null || error === void 0 ? void 0 : error.data) === null || _d === void 0 ? void 0 : _d.message) ? error.data.message
                : ((_e = error === null || error === void 0 ? void 0 : error.data) === null || _e === void 0 ? void 0 : _e.error) ? error.data.error
                    : error;
};
export var getFormErrors = function (error) {
    var _a, _b;
    return ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) ? error.response.data.errors : undefined;
};
