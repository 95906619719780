import moment from 'moment';
export var FileType;
(function (FileType) {
    FileType["pdf"] = "pdf";
    FileType["ipynb"] = "ipynb";
    FileType["jpg"] = "jpg";
    FileType["jpeg"] = "jpeg";
    FileType["py"] = "py";
    FileType["ppt"] = "ppt";
    FileType["pptx"] = "pptx";
    FileType["doc"] = "doc";
    FileType["docx"] = "docx";
    FileType["xls"] = "xls";
    FileType["xlsx"] = "xlsx";
    FileType["txt"] = "txt";
    FileType["png"] = "png";
})(FileType || (FileType = {}));
export var isPdf = function (fileName) {
    return isFileType(fileName, FileType.pdf);
};
export var downloadFile = function (data, filename) {
    var url = window.URL.createObjectURL(new Blob([data]));
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "" + filename);
    document.body.appendChild(link);
    link.click();
};
export var downloadXLS = function (data, filename) {
    var url = window.URL.createObjectURL(new Blob([data]));
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename + "-" + moment() + ".xlsx");
    document.body.appendChild(link);
    link.click();
};
export var downloadICS = function (data, filename) {
    var url = window.URL.createObjectURL(new Blob([data]));
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename + "-" + moment() + ".ics");
    document.body.appendChild(link);
    link.click();
};
/**
 *
 * @param files
 * @param size in MB
 */
export var checkFilesSize = function (files, size) {
    if (files) {
        var totalSize = files.reduce(function (acc, curr) {
            return acc + curr.size;
        }, 0);
        if (totalSize > size * 1024 * 1024) {
            return "Max file upload limit is " + size + "MB";
        }
        return undefined;
    }
    return undefined;
};
export var getFileNameAndExt = function (file) {
    // eslint-disable-next-line no-useless-escape
    var _a = file.split(/\.(?=[^\.]+$)/), fileName = _a[0], ext = _a[1];
    return {
        fileName: fileName,
        ext: ext,
    };
};
export var isValidExtension = function (extensions, extension) {
    var extensionList = extensions.map(function (ext) { return ext.toLocaleLowerCase(); });
    return extensionList.includes(extension.toLocaleLowerCase());
};
export var getFileType = function (url) {
    var _a;
    var fileType = url.split('.').pop();
    return (fileType === null || fileType === void 0 ? void 0 : fileType.includes('?')) ? (_a = fileType === null || fileType === void 0 ? void 0 : fileType.split('?')) === null || _a === void 0 ? void 0 : _a[0] : fileType;
};
export var isFileType = function (fileName, fileType) {
    var fileNameAndExt = getFileNameAndExt(fileName);
    if (fileNameAndExt.ext) {
        return fileNameAndExt.ext.toLocaleLowerCase() === fileType;
    }
    return false;
};
export var getBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) { return reject(error); };
    });
};
export var convertToMultipart = function (values) {
    var data = new FormData();
    for (var key in values) {
        if (values.hasOwnProperty(key)) {
            data.append(key, values[key]);
        }
    }
    return data;
};
export var isLargerThan = function (sizeInBytes, deltaInMB) {
    if (sizeInBytes) {
        if (sizeInBytes > deltaInMB * 1024 * 1000) {
            return true;
        }
        return false;
    }
    return false;
};
export var downloadUrlFile = function (url, fileName) {
    var link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
