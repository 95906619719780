import * as React from 'react';
/**
 * Check box without react-final-form
 * @param props
 */
export var FCheckbox = function (props) {
    var legend = props.legend, name = props.name, className = props.className, id = props.id, disabled = props.disabled, checkboxWrapperClassName = props.checkboxWrapperClassName, hideExtraLabel = props.hideExtraLabel, checked = props.checked, value = props.value;
    return (React.createElement("div", { className: "form-group" },
        !hideExtraLabel && React.createElement("label", { htmlFor: id || name }),
        React.createElement("div", { className: checkboxWrapperClassName || 'custom-control custom-checkbox mb-2' },
            React.createElement("input", { id: id ? id : name, className: className || 'custom-control-input', type: "checkbox", checked: checked, value: value, onChange: function (e) {
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }, disabled: disabled }),
            ' ',
            React.createElement("label", { className: "custom-control-label", htmlFor: id || name },
                React.createElement("span", null, legend)))));
};
