import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routeUrl } from './LinkUrl';
import useGoogleAnalytics from 'src/utils/googleAnalytics';
import { LoaderWrap } from '@fuse/ui-components/dist/LoaderWrap';
import PrivateRoute from '@fuse/cognito/dist/utils/PrivateRoute';
import { ALL_ROLES } from 'src/components/PrivateRoute/constants';

const ErrorPage = React.lazy(() => import('./scenes/404/'));
const Login = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.Login })));
const Logout = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.Logout })));
const ForgotPassword = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.ForgotPassword })));
const ChangePassword = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.ChangePassword })));
const InAppWebViewAnnotation = React.lazy(() => import('src/scenes/InAppWebViewAnnotation'));
const ParentPortal = React.lazy(() => import('./ParentPortal/ParentPortalRoutes'));
const ParentSignUp = React.lazy(() => import('./ParentPortal/Signup'));
const ParentForgotPassword = React.lazy(() => import('./ParentPortal/ForgotPassword'));
const PrivateRoutes = React.lazy(() => import('./PrivateRoutes'));

const MainRoutes = () => {
  useGoogleAnalytics();

  return (
    <React.Suspense fallback={<LoaderWrap isLoading={true} type="Main" />}>
      <Switch>
        <Route exact path={routeUrl.notFound} component={ErrorPage} />
        {/* Route for mobile pdf annotation flutter application */}
        <Route exact path={routeUrl.student.inAppWebViewAnnotation} component={InAppWebViewAnnotation} />

        {/* for parent portal */}
        {isParentPortal() && <Route exact path={routeUrl.signUp} component={ParentSignUp} />}
        <Route exact path={routeUrl.login} component={isParentPortal() ? ParentPortal : Login} />
        <Route exact path={routeUrl.logout} component={Logout} />
        <Route
          exact
          path={routeUrl.forgotPassword}
          component={isParentPortal() ? ParentForgotPassword : ForgotPassword}
        />
        <Route exact path={routeUrl.changePassword} component={ChangePassword} />
        <PrivateRoute path={routeUrl.root} component={PrivateRoutes} roles={ALL_ROLES} />
      </Switch>
    </React.Suspense>
  );
};

export const isParentPortal = () => {
  return window.location.origin?.includes('.parent');
};

export default MainRoutes;
