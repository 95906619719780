var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { SCHOOL_FORBIDDEN, SCHOOL_TERMINATED, SERVER_UNAVAILABLE, SESSION_EXPIRED, UNAUTHORIZED, } from '@fuse/messages/dist/common';
import { SetStorageComponent, removeCognitoSession, getQueryStringParams, clearStorageComponent } from '@fuse/utils';
import { CognitoAuth } from '../Cognito';
import { getQueryParams } from '@fuse/utils/dist/urlUtils';
var config = {
    rest_url: process.env.REACT_APP_REST_URL,
};
axios.defaults.baseURL = config.rest_url;
// axios.defaults.timeout = 15000
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    var _a, _b;
    if (error) {
        if (error.response) {
            // parse error message for response type 'blob'
            if (error.request.responseType === 'blob' &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf('json') !== -1 &&
                error.response.status === 401) {
                return new Promise(function (resolve, reject) {
                    var reader = new FileReader();
                    reader.onload = function () {
                        error.response.data = JSON.parse(reader.result);
                        resolve(retryRequest(error));
                    };
                    reader.onerror = function () {
                        reject(error);
                    };
                    reader.readAsText(error.response.data);
                });
            }
            if (error.response.status === 401) {
                return retryRequest(error);
            }
            // check school forbidden user && error.response.data && error.response.data.error === 'invalid_token'
            if (error.response.status === 403 && ((_a = error.response.data) === null || _a === void 0 ? void 0 : _a.message) === SCHOOL_FORBIDDEN) {
                redirectUrl(getQueryParams({ error: SCHOOL_FORBIDDEN }));
                return;
            }
            else if (error.response.status === 403) {
                redirectUrl(getQueryParams({ error: UNAUTHORIZED }));
                return;
            }
            else if (error.response.status === 403 && ((_b = error.response.data) === null || _b === void 0 ? void 0 : _b.message) === SCHOOL_TERMINATED) {
                redirectUrl(getQueryParams({ error: SCHOOL_TERMINATED }));
                clearStorageComponent();
                return;
            }
        }
        else if (error.response.status === 503) {
            redirectUrl("" + getQueryParams({ error: SERVER_UNAVAILABLE, redirect_url: encodeURIComponent(getRedirectUrl()) }));
            return;
        }
        return Promise.reject(error);
    }
    return Promise.reject(error);
});
/**
 *
 * @param error retry request by getting new access_token with refresh_token
 */
var retryRequest = function (error) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        //check unauthorized user
        return [2 /*return*/, CognitoAuth.currentSession()
                .then(function (session) {
                var originalRequest = error.config;
                originalRequest._retry = true;
                originalRequest.headers = __assign(__assign({}, originalRequest.headers), {
                    Authorization: 'bearer ' + session.accessToken.jwtToken,
                    idToken: session.idToken.jwtToken,
                });
                delete originalRequest.headers['authorization'];
                SetStorageComponent('id_token', session.idToken.jwtToken);
                SetStorageComponent('access_token', session.accessToken.jwtToken);
                return axios(originalRequest);
            })
                .catch(function (retryError) { return __awaiter(void 0, void 0, void 0, function () {
                var queryString;
                return __generator(this, function (_a) {
                    console.log('RetryError:: ', retryError);
                    if (window.location) {
                        queryString = getQueryStringParams(window.location.search);
                        if (queryString && queryString.error !== SESSION_EXPIRED) {
                            // add redirect url for amplify to redirect after login
                            redirectUrl("" + getQueryParams({ error: SESSION_EXPIRED, redirect_url: encodeURIComponent(getRedirectUrl()) }));
                        }
                    }
                    else {
                        removeCognitoSession();
                        CognitoAuth.signOut();
                    }
                    return [2 /*return*/];
                });
            }); })];
    });
}); };
var redirectUrl = function (url) {
    window.location.href = "/?" + url;
};
var getRedirectUrl = function () {
    return window.location.search
        ? "" + window.location.pathname + window.location.search
        : "" + window.location.pathname;
};
export default axios;
