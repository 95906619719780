import { useEffect } from 'react';
import ReactGA from 'react-ga';
import config from 'src/config/common';
import { useRouter } from '@fuse/utils/dist/useRouter';

const useGoogleAnalytics = () => {
  const { history } = useRouter();

  useEffect(() => {
    ReactGA.initialize(config.googleAnalytics || 'UA-xxxxxxxxx-1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.listen((location: any) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }, [history]);
};

export default useGoogleAnalytics;
