export var addClassNameToEveryATagsOnAHtmlString = function (htmlString) {
    var _a;
    var className = 'styled-editor-link';
    var htmlStringWithClassNameAddedToATag = (_a = htmlString === null || htmlString === void 0 ? void 0 : htmlString.split('<a')) === null || _a === void 0 ? void 0 : _a.map(function (tag) {
        if (tag.includes('</a>')) {
            return "<a class=\"" + className + "\"" + tag;
        }
        else {
            return tag;
        }
    });
    return htmlStringWithClassNameAddedToATag === null || htmlStringWithClassNameAddedToATag === void 0 ? void 0 : htmlStringWithClassNameAddedToATag.join('');
};
// Return string after Trimming html tags and inline styles from a editor string
export var trimHtmlTagsAndStyles = function (wysiwygString) {
    if (wysiwygString === void 0) { wysiwygString = ''; }
    return wysiwygString === null || wysiwygString === void 0 ? void 0 : wysiwygString.replace(/<(.|\n)*?>/g, '');
};
// Checks if the editor string is greater than provided count value after trimHtmlTagsAndStyles() 
// function is applied on editor string
export var checkIfMore = function (string, count) {
    if (string === void 0) { string = ''; }
    var trimmedString = trimHtmlTagsAndStyles(string);
    return trimmedString.length > count;
};
// Splits received string depending on received count
export var splitString = function (string, count) {
    if (string === void 0) { string = ''; }
    // const trimmedString = trimHtmlTagsAndStyles(string);
    return (string === null || string === void 0 ? void 0 : string.substring(0, count)) + "...";
};
