var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { plural } from 'pluralize';
import { fuseTerminology } from '../Constant';
export var isFuseUpskill = window.location.host.includes('fuseupskill');
export var terminology = (function () {
    return Object.keys(fuseTerminology).reduce(function (obj, item) {
        var _a;
        return (__assign(__assign({}, obj), (_a = {}, _a[item] = fuseTerminology[item][isFuseUpskill ? 'fuseUpskill' : 'fuseClassroom'], _a)));
    }, {});
})();
export var pluralize = function (word) {
    return plural(word);
};
export var postFixWithD = function (word) {
    return word + "d";
};
