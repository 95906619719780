var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Pagination from 'react-js-pagination';
import { FSelect } from '../FSelect';
var sizePerPageList = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
    { label: '200', value: '200' },
];
export var FPagination = function (props) {
    var activePage = props.activePage, itemsCountPerPage = props.itemsCountPerPage, loading = props.loading, totalItemsCount = props.totalItemsCount;
    var customTotal = function () { return (React.createElement("div", { className: "ml-3" },
        React.createElement("span", { className: "react-bootstrap-table-pagination-total" },
            "Showing ",
            itemsCountPerPage * (activePage - 1) + 1,
            " to",
            ' ',
            totalItemsCount < itemsCountPerPage * activePage ? totalItemsCount : itemsCountPerPage * activePage,
            " of",
            ' ',
            totalItemsCount,
            " Results"))); };
    return (React.createElement("div", { className: "d-md-flex align-items-baseline justify-content-between mt-2" },
        React.createElement("div", { className: "d-flex align-items-baseline" }, totalItemsCount !== 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "ml-3" },
                React.createElement(FSelect, { options: sizePerPageList, selectedValue: "" + itemsCountPerPage, disabled: loading, onInputChange: function (e) {
                        var value = e.target.value;
                        if (itemsCountPerPage !== value)
                            props.onChangePageOrSize(1, parseInt(value));
                    } })),
            customTotal()))),
        React.createElement("div", null,
            React.createElement(Pagination, __assign({ itemClass: "page-item", linkClass: "page-link", prevPageText: "Prev Page", hideDisabled: true, nextPageText: "Next Page", hideFirstLastPages: true, onChange: function (page) {
                    if (!loading && activePage !== page) {
                        props.onChangePageOrSize(page, itemsCountPerPage);
                    }
                } }, props)))));
};
