import { FORUM_PREFERENCE } from '../../constants/actions';

const initialState = {
  forumPreference: {} as FORUM_PREFERENCE,
  loadingForumPreference: false,
};

interface action {
  type: string;
  payload: {};
}

export default (state = initialState, action: action) => {
  const { type, payload } = action;
  switch (type) {
    case FORUM_PREFERENCE.GET_FORUM_PREFERENCE:
      return {
        ...state,
        forumPreference: payload,
      };
    case FORUM_PREFERENCE.UPDATE_FORUM_PREFERENCE:
      return {
        ...state,
        forumPreference: payload,
      };

    case FORUM_PREFERENCE.START_LOADING:
      return {
        ...state,
        loadingForumPreference: true,
        forumPreference: {},
      };

    case FORUM_PREFERENCE.STOP_LOADING:
      return {
        ...state,
        loadingForumPreference: false,
      };

    default:
      return state;
  }
};
