export var fuseTerminology = {
    instructor: {
        fuseClassroom: 'Instructor',
        fuseUpskill: 'Trainer',
    },
    student: {
        fuseClassroom: 'Student',
        fuseUpskill: 'Participant',
    },
    schoolAdmin: {
        fuseClassroom: 'School Admin',
        fuseUpskill: 'Admin',
    },
    school: {
        fuseClassroom: 'School',
        fuseUpskill: 'Institution',
    },
    degree: {
        fuseClassroom: 'Degree',
        fuseUpskill: 'Department',
    },
    program: {
        fuseClassroom: 'Program',
        fuseUpskill: 'Training Program',
    },
    academic: {
        fuseClassroom: 'Academic',
        fuseUpskill: 'Training',
    },
    semester: {
        fuseClassroom: 'Semester',
        fuseUpskill: 'Batch',
    },
    classroom: {
        fuseClassroom: 'Classroom',
        fuseUpskill: 'Training Course',
    },
    currentCourse: {
        fuseClassroom: 'Current Course',
        fuseUpskill: 'Enrolled Course',
    },
    quiz: {
        fuseClassroom: 'Quiz',
        fuseUpskill: 'Quiz',
    },
    assignment: {
        fuseClassroom: 'Assignment',
        fuseUpskill: 'Assessment',
    },
    class: {
        fuseClassroom: 'Class',
        fuseUpskill: 'Session',
    },
    gradeTitle: {
        fuseClassroom: 'Grade',
        fuseUpskill: 'Report',
    },
    grade: {
        fuseClassroom: 'Grade',
        fuseUpskill: 'Score',
    },
    myClassroom: {
        fuseClassroom: 'My Classroom',
        fuseUpskill: '',
    },
    fuseClassroom: {
        fuseClassroom: 'Fuse Classroom',
        fuseUpskill: 'Fuse Upskill',
    },
    attendance: {
        fuseClassroom: 'Attendance',
        fuseUpskill: 'Participation',
    },
    physical: {
        fuseClassroom: 'Physical',
        fuseUpskill: 'Onsite',
    },
    paper: {
        fuseClassroom: 'Paper',
        fuseUpskill: 'Online',
    },
    level: {
        fuseClassroom: 'Level',
        fuseUpskill: 'Level',
    },
    liveClass: {
        fuseClassroom: 'Live Class',
        fuseUpskill: 'Live Class',
    },
    parent: {
        fuseClassroom: 'Parent',
        fuseUpskill: 'Parent',
    },
    newsFeed: {
        fuseClassroom: 'News Feed',
        fuseUpskill: 'News Feed',
    },
};
