import '@fuse/design/dist/classroom/style.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import config, { activeEnv } from './config/common';
import MainRoutes from './routes';
import Store from './store';
import './styles/sassV2/style.min.scss';
import { isFuseUpskill } from '@fuse/terminology';
import { FHelmet } from '@fuse/ui-components';

if (activeEnv === 'production' && config.sentry_url) {
  Sentry.init({
    release: `fuse-ai-classroom-${activeEnv}@` + config.package_version,
    dsn: config.sentry_url,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <BrowserRouter>
        <div className={isFuseUpskill ? 'upskill' : ''}>
          <FHelmet faviconUrl={isFuseUpskill ? '/fuseupskill-favicon.png' : '/favico.png'} />
          <MainRoutes />
        </div>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
// registerServiceWorker();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}
