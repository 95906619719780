import { JSEncrypt } from 'jsencrypt';
import { PUBLIC_KEY } from '../constants';
export var isValidEmail = function (email) {
    //eslint-disable-next-line
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).trim().toLowerCase());
};
export var hasNumber = function (newPassword) {
    var numberRegex = /\d/;
    return numberRegex.test(newPassword);
};
export var validatePassword = function (value, allValues) {
    if (!value) {
        return "Required.";
    }
    else if (value && value.length < 8) {
        return "Password must have length greater than or equal to 8.";
    }
    else if (value && !hasNumber(value)) {
        return "Password must contain a number.";
    }
    return undefined;
};
export var getEncryptedPassword = function (password) {
    if (PUBLIC_KEY) {
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(PUBLIC_KEY);
        return encrypt.encrypt(password);
    }
};
export var getErrorMessage = function (error) {
    var _a, _b, _c, _d, _e;
    return ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) ? error.response.data.message
        : ((_c = error === null || error === void 0 ? void 0 : error.data) === null || _c === void 0 ? void 0 : _c.error_description) ? error.data.error_description
            : ((_d = error === null || error === void 0 ? void 0 : error.data) === null || _d === void 0 ? void 0 : _d.message) ? error.data.message
                : ((_e = error === null || error === void 0 ? void 0 : error.data) === null || _e === void 0 ? void 0 : _e.error) ? error.data.error
                    : error;
};
export var getStatusCode = function (error) {
    var _a, _b;
    return ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.status) ? error.response.data.status : null;
};
export var getCognitoEmail = function (prefix, email) {
    return prefix + "_" + email;
};
