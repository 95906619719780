var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field } from 'react-final-form';
export var FinalInput = function (props) {
    var legend = props.legend, name = props.name, type = props.type, formProps = props.formProps, required = props.required, disabled = props.disabled, validate = props.validate, min = props.min, max = props.max, inputClassName = props.inputClassName, errorClass = props.errorClass, requirementMsg = props.requirementMsg, className = props.className, inputWrapClassName = props.inputWrapClassName, placeholder = props.placeholder, disableAutoComplete = props.disableAutoComplete, autoFocus = props.autoFocus, hideAsterisk = props.hideAsterisk, id = props.id, inline = props.inline, inputRef = props.inputRef, decimal = props.decimal;
    var isRequired = function (value) { return (value && value.toString().trim() ? undefined : 'Required'); };
    return (React.createElement(Field, { name: name, validate: validate ? validate : !required ? undefined : isRequired }, function (_a) {
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: (className ? className : 'form-group') + " " + (inline ? 'form-inline' : '') + " " + (meta.error && (meta.dirty || meta.submitFailed) && meta.touched ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: id || name },
                legend,
                (required || validate) && !hideAsterisk ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement("div", { className: (inputWrapClassName ? inputWrapClassName : '') + " position-relative" },
                disableAutoComplete ? (React.createElement("input", __assign({}, input, { ref: inputRef, id: id || name, type: type ? type : 'text', className: (type === 'file' ? '' : 'form-control') + " " + (inputClassName ? inputClassName : ''), disabled: disabled, onChange: function (e) {
                        formProps.form.change(name, e.target.value);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }, onBlur: function (e) {
                        if (props.onBlur) {
                            props.onBlur(e);
                        }
                    }, onFocus: function (e) {
                        if (props.onFocus) {
                            props.onFocus(e);
                        }
                    }, onKeyUp: function (e) {
                        if (props.onEnter && e.key === 'Enter') {
                            props.onEnter(e);
                        }
                    }, min: min, max: max, onWheel: function (e) {
                        //disable change in input for number input onscroll inside input field
                        if (type === 'number') {
                            e.target.blur();
                        }
                    }, placeholder: placeholder || '', autoComplete: "new-password", autoFocus: autoFocus ? true : false, step: "" + (type === 'number' && decimal ? 0.01 : '') }))) : (React.createElement("input", __assign({}, input, { ref: inputRef, id: id || name, type: type ? type : 'text', className: (type === 'file' ? '' : 'form-control') + " " + (inputClassName ? inputClassName : ''), disabled: disabled, onChange: function (e) {
                        formProps.form.change(name, e.target.value);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }, onBlur: function (e) {
                        if (props.onBlur) {
                            props.onBlur(e);
                        }
                    }, onFocus: function (e) {
                        if (props.onFocus) {
                            props.onFocus(e);
                        }
                    }, onKeyUp: function (e) {
                        if (props.onEnter && e.key === 'Enter') {
                            props.onEnter(e);
                        }
                    }, min: min, max: max, onWheel: function (e) {
                        //disable change in input for number input onscroll inside input field
                        if (type === 'number') {
                            e.target.blur();
                        }
                    }, placeholder: placeholder || '', autoFocus: autoFocus ? true : false, step: "" + (type === 'number' && decimal ? 0.01 : '') }))),
                type && type === 'password' && (React.createElement("span", { onClick: function () {
                        var togglePassword = document.querySelector("#" + (id || name) + "-togglePassword");
                        var password = document.querySelector("#" + (id || name));
                        if (password) {
                            var inputtype = password.getAttribute('type') === 'password' ? 'text' : 'password';
                            password.setAttribute('type', inputtype);
                            if (togglePassword) {
                                togglePassword.classList.toggle('icon-hide');
                            }
                        }
                    }, className: "icon-position" },
                    React.createElement("i", { className: "icon-view icn-md icon-hide", id: (id || name) + "-togglePassword" }))),
                (meta.error && (meta.dirty || meta.submitFailed) && meta.touched) || requirementMsg ? (React.createElement("span", { className: errorClass && !requirementMsg ? errorClass : 'required' }, requirementMsg !== undefined ? requirementMsg : meta.error)) : null)));
    }));
};
