var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var FETCH_APP_LIST = 'FETCH_APP_LIST';
export var HomeRegistryInitialState = {
    appRegistry: [],
    schoolProductType: '',
    id: '',
};
export default function reducers(state, action) {
    if (state === void 0) { state = HomeRegistryInitialState; }
    if (action.type === FETCH_APP_LIST) {
        return __assign(__assign({}, state), action.payload);
    }
    return state;
}
