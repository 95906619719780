import React from 'react';
import { Link, useHistory } from 'react-router-dom';
export var BlueHeader = function (props) {
    var goBack = useHistory().goBack;
    var title = props.title, redirectUrl = props.redirectUrl;
    return (React.createElement("div", { className: "blue-header" }, redirectUrl ? (React.createElement(Link, { to: redirectUrl, className: "text-white font-weight-semibold" },
        React.createElement("span", { className: "icon-arrow icn-lg mr-3" }),
        " ",
        title)) : (React.createElement("button", { className: "btn btn-link text-white font-weight-semibold", onClick: function () {
            if (props.onClick) {
                props.onClick();
            }
            else {
                goBack();
            }
        } },
        React.createElement("span", { className: "icon-arrow icn-lg mr-3 pointer" }),
        " ",
        title))));
};
