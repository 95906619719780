import React, { useRef } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { ProgressBar } from 'react-bootstrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FTooltip } from '../../FTooltip';
var ItemTypes = {
    CARD: 'card',
};
var Thumbnail = function (props) {
    var _a;
    var id = props.id, index = props.index, moveCard = props.moveCard, file = props.file, onDelete = props.onDelete, previewFile = props.previewFile, imageCompressionProcessList = props.imageCompressionProcessList, compressedFileList = props.compressedFileList, isAddingFiles = props.isAddingFiles;
    var ref = useRef(null);
    var handleClick = function () {
        props.onPreview(compressedFileList[index], index);
    };
    var handleDelete = function (indexValue, fileKey) {
        onDelete(indexValue, fileKey);
    };
    var _b = useDrop({
        accept: ItemTypes.CARD,
        hover: function (item, monitor) {
            if (!ref.current) {
                return;
            }
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    }), drop = _b[1];
    var _c = useDrag({
        item: { type: ItemTypes.CARD, id: id, index: index },
        end: function (item, monitor) { },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), drag = _c[1];
    drag(drop(ref));
    var compressedFile = compressedFileList.find(function (tempFile, idx) { return file.finalName === tempFile.finalName; });
    if (!compressedFile) {
        if (!compressedFile && compressedFileList && index === compressedFileList.length - 1) {
            compressedFile = compressedFileList[index];
        }
    }
    return (React.createElement("li", { className: "file-image " + (((_a = previewFile.file) === null || _a === void 0 ? void 0 : _a.finalName) === props.file.finalName ? 'active' : ''), style: isAddingFiles ? { pointerEvents: 'none' } : {} },
        React.createElement(FTooltip, { tooltipMessage: "Re-Order" },
            React.createElement("div", { ref: ref, className: "move" },
                React.createElement("span", { className: "icon-six-dots" }))),
        React.createElement("div", { className: "image pointer " + (!file.isImage ? 'd-flex justify-content-center align-items-center' : ''), onClick: function () { return handleClick(); } }, file.isImage ? (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(CompressionProgressbar, { imageCompressionProcessList: imageCompressionProcessList, file: file })),
            file && compressedFile && compressedFile.preview && (React.createElement("img", { src: compressedFile.preview, alt: file.finalName })))) : (React.createElement("div", { className: "file-preview-name text-truncate p-2" }, file.finalName))),
        !isAddingFiles && (React.createElement(FTooltip, { tooltipMessage: "Remove" },
            React.createElement("div", { className: "delete", onClick: function () { return handleDelete(index, file.finalName); } },
                React.createElement("span", { className: "icon-cross icn-sm" }))))));
};
var Thumbnails = function (props) {
    var moveCard = props.moveCard, onPreview = props.onPreview, onDelete = props.onDelete, previewFile = props.previewFile, imageCompressionProcessList = props.imageCompressionProcessList, compressedFileList = props.compressedFileList, isAddingFiles = props.isAddingFiles, fileList = props.fileList;
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement("ul", null, fileList.map(function (file, index) { return (React.createElement(Thumbnail, { id: file.name + '_' + index, key: file.finalName, index: index, file: file, moveCard: moveCard, onDelete: onDelete, onPreview: onPreview, previewFile: previewFile, compressedFileList: compressedFileList, isAddingFiles: isAddingFiles, imageCompressionProcessList: imageCompressionProcessList })); }))));
};
export default Thumbnails;
var CompressionProgressbar = function (props) {
    var imageCompressionProcessList = props.imageCompressionProcessList, file = props.file;
    if (imageCompressionProcessList && imageCompressionProcessList[file.finalName]) {
        return (React.createElement(React.Fragment, null, imageCompressionProcessList[file.finalName] < 100 && (React.createElement("div", null,
            React.createElement(ProgressBar, { className: "full-length", variant: 'info', now: imageCompressionProcessList[file.finalName], label: imageCompressionProcessList[file.finalName] + "%" })))));
    }
    return (React.createElement("div", null,
        React.createElement(ProgressBar, { className: "full-length", variant: 'info', now: 0, label: "0%" })));
};
