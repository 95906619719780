export var NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
// public key for encrypt password
export var PUBLIC_KEY = process.env.REACT_APP_ENCRYPT_PUBLIC_KEY;
// routes constants
export var LOGIN_ROUTE = '/login';
export var LOGOUT_ROUTE = '/logout';
export var CHANGE_PASSWORD_ROUTE = '/changepassword';
export var FORGOT_PASSWORD_ROUTE = '/forgotpassword';
export var NOT_FOUND_ROUTE = '/404';
export var ROOT_ROUTE = '/';
export var HOME_ROUTE = '/home';
export var PROFILE_ROUTE = '/profile';
export var SIGN_UP_ROUTE = '/signup';
