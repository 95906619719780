import * as React from 'react';
export var FSelect = function (props) {
    var id = props.id, disabled = props.disabled, placeholder = props.placeholder, tooltipText = props.tooltipText;
    var optionRows = props.options.map(function (option, index) {
        if (option.secondaryValue) {
            return (React.createElement("option", { key: index, value: option.value + "," + option.secondaryValue }, option.label));
        }
        else {
            return (React.createElement("option", { key: index, value: option.value }, option.label));
        }
    });
    return (React.createElement("select", { id: id, className: "form-control", name: props.name, disabled: disabled, title: tooltipText, value: props.selectedValue, onChange: props.onInputChange, placeholder: placeholder, style: {
            maxWidth: '300px',
        } }, optionRows));
};
