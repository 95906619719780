var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useRouter, getUserStorageComponent, setCognitoSession } from '@fuse/utils';
import { Form } from 'react-final-form';
import { CognitoAuth } from '@fuse/config/dist/Cognito';
import { FModal, LoaderWrap, FinalInput, FButton } from '@fuse/ui-components';
import { validatePassword } from '../';
import { USER_INFO } from '@fuse/messages/dist/common';
import { ROOT_ROUTE } from '../../constants';
import useAuth from '../useAuth';
export var AmplifySessionExpired = function () {
    var logout = useAuth().logout;
    var query = useRouter().query;
    var _a = useState({ loading: false, error: '', data: null }), loginData = _a[0], setLoginData = _a[1];
    var userData = getUserStorageComponent(USER_INFO);
    var onLogin = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var password;
        return __generator(this, function (_a) {
            CognitoAuth.signOut();
            setLoginData(__assign(__assign({}, loginData), { error: '', loading: true }));
            password = values.password;
            loginUser(password);
            return [2 /*return*/];
        });
    }); };
    var loginUser = function (password) { return __awaiter(void 0, void 0, void 0, function () {
        var user, redirect_url, loginError_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(userData && userData.email && password)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, CognitoAuth.signIn(userData.email, password)];
                case 2:
                    user = _a.sent();
                    if (user && user.signInUserSession) {
                        setCognitoSession(user);
                        redirect_url = query.redirect_url;
                        if (redirect_url) {
                            window.location.replace(redirect_url);
                        }
                        else {
                            window.location.replace(ROOT_ROUTE);
                        }
                    }
                    else {
                        setLoginData(__assign(__assign({}, loginData), { error: 'Something went wrong. Please try again', loading: false }));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    loginError_1 = _a.sent();
                    if (loginError_1 && loginError_1.message) {
                        setLoginData(__assign(__assign({}, loginData), { error: loginError_1.message, loading: false }));
                    }
                    else {
                        setLoginData(__assign(__assign({}, loginData), { error: 'Something went wrong. Please try again.', loading: false }));
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(FModal, { backdrop: "static", title: "", className: "", hideHeader: true, modal: true, toggleModal: function () { }, disableCloseButton: true }, userData && userData.email && (React.createElement("div", { className: "session-expired" },
        React.createElement(LoaderWrap, { isLoading: loginData.loading }),
        React.createElement("div", { className: "img-wrap" },
            React.createElement("img", { src: "/images/empty-states/session-expired.svg", alt: "session-expired" })),
        React.createElement("p", { className: "session-expired--title" }, "Session Expired"),
        React.createElement("p", { className: "session-expired--text" },
            "Your session has expired due to inactivity ",
            React.createElement("span", { className: "font-weight-semibold" }, userData.email),
            ". Please login again."),
        React.createElement(Form, { onSubmit: onLogin, render: function (formProps) { return (React.createElement("form", { onSubmit: formProps.handleSubmit },
                React.createElement(FinalInput, { name: "password", type: "password", required: true, hideAsterisk: true, legend: "Password", className: "form-inline", errorClass: "error", formProps: formProps, validate: validatePassword, onChange: function () {
                        setLoginData(__assign(__assign({}, loginData), { error: '' }));
                    }, inputWrapClassName: "d-flex flex-column session-expired--input" }),
                React.createElement("div", { className: "error text-center", style: { marginLeft: '70px', width: '200px' } }, loginData.error),
                React.createElement("div", { className: "bottom-btn text-right mt-4" },
                    React.createElement(FButton, { className: "btn btn-md btn-cancel mr-4", label: "Logout", onClick: logout }),
                    React.createElement(FButton, { className: "btn btn-md btn-green", type: "submit", disabled: loginData.loading || formProps.pristine || formProps.submitting, label: "Login" })))); } })))));
};
