// Error response type
export var SCHOOL_FORBIDDEN = 'school_forbidden';
export var SERVER_UNAVAILABLE = 'server_unavailable';
export var ROLE_FORBIDDEN = 'role_forbidden';
export var SERVER_ERROR = 'server_error';
export var INVALID_TOKEN = 'invalid_token';
export var SESSION_EXPIRED = 'session_expired';
export var UNAUTHORIZED = 'unauthorized';
export var SCHOOL_TERMINATED = 'school_terminated';
// local storage key
export var ACCESS_TOKEN = 'access_token';
export var ID_TOKEN = 'id_token';
export var EXPIRES_AT = 'expires_at';
export var USER_INFO = 'userinfo';
export var SHOW_ROTATION_WARNING = 'showRotationWarning';
export var FIREBASE_TOKEN = 'firebase_token';
export var ANALYTICS_FILTER = 'analytics_filter';
export var AMPLIFY = 'amplify';
export var TRUE = 'true';
export var FALSE = 'false';
export var BOOLEAN = 'boolean';
export var AUTH = 'auth';
export var SIGN_IN = 'signIn';
export var CUSTOM_OAUTH_STATE = 'customOAuthState';
export var OAUTH_SIGN_OUT = 'oAuthSignOut';
export var SIGN_OUT = 'signOut';
export var IS_HOSTED_UI = 'amplify-redirected-from-hosted-ui';
//error message
export var SERVER_UNAVAILABLE_MSG = 'Network Error';
