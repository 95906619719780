import * as createHistory from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';

/**
 * Creates a history object that uses the HTML5 history API including
 * pushState, replaceState, and the popstate event.
 *
 */
const history = createHistory.createBrowserHistory();

export { history };

const addLoggingToDispatch = () => {
  // Build the middleware for intercepting and dispatching navigation actions
  const historyMiddleware = routerMiddleware(history);
  const middlewares =
    process.env.REACT_APP_API_ENV === 'production'
      ? applyMiddleware(thunk, historyMiddleware)
      : applyMiddleware(thunk, logger, historyMiddleware);
  return composeWithDevTools(middlewares);
};

export const addPromiseSupportToDispatch = (store: any) => {
  const rawDispatch = store.dispatch;
  return (action: any) => {
    if (typeof action.then === 'function') {
      return action.then(action);
    }
    return rawDispatch(action);
  };
};

const Store = createStore(reducers, addLoggingToDispatch());

Store.dispatch = addPromiseSupportToDispatch(Store);

export default Store;
