var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import imageCompression from 'browser-image-compression';
import Thumbnails from './components/Thumbnails';
import ImagePreview from './components/ImagePreview';
import { Prompt } from 'react-router-dom';
import { showError } from '@fuse/utils';
import { isImageFile } from './utils';
import { useConfirmationModal } from '../ConfirmationModal';
import { FTooltip } from '../FTooltip';
var allowedMaxFiles = 50;
var UploadFileWithPreview = function (props) {
    var _a = props.supportedExtensions, supportedExtensions = _a === void 0 ? [] : _a, _b = props.confirmationOnCancel, confirmationOnCancel = _b === void 0 ? true : _b, _c = props.convertAllImageToPdf, convertAllImageToPdf = _c === void 0 ? false : _c, _d = props.maxFileSize, maxFileSize = _d === void 0 ? 100 : _d, imageUploadRef = props.imageUploadRef;
    var _e = useConfirmationModal(), openConfirmationModal = _e.openConfirmationModal, confirmationModal = _e.confirmationModal;
    var _f = useState([]), uploadedAnswerFiles = _f[0], setUploadedAnswerFiles = _f[1];
    var _g = useState(), _imageCompressionProcessList = _g[0], _setImageCompressionProcessList = _g[1];
    var _h = useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = useState(''), compiledFilename = _j[0], setCompiledFilename = _j[1];
    var _k = useState({
        file: null,
        index: -1,
    }), previewFile = _k[0], setPreviewFile = _k[1];
    //used to disable other actions when files are being added
    var _l = useState(false), isAddingFiles = _l[0], setIsAddingFiles = _l[1];
    var _m = useState([]), _originalImageList = _m[0], _setOriginalImageList = _m[1];
    //to keep track of recently deleted image
    var _o = useState(-1), recentDeletedIndex = _o[0], setRecentDeletedIndex = _o[1];
    var isFileGreaterThan = useMemo(function () {
        var totalFileSize = uploadedAnswerFiles.reduce(function (sum, file) {
            return sum + file.size;
        }, 0);
        return totalFileSize >= maxFileSize * 1024 * 1024 ? true : false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedAnswerFiles]);
    useEffect(function () {
        if (props.show && (imageUploadRef === null || imageUploadRef === void 0 ? void 0 : imageUploadRef.current)) {
            imageUploadRef.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);
    var handleSubmit = function () {
        props.handleSubmit({ files: uploadedAnswerFiles, compiledFilename: compiledFilename });
    };
    var compressImage = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var imageFile, options, compressedFile, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    imageFile = file;
                    options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: false,
                        onProgress: function (value) {
                            // set compression percentage to show compression progress on thumbnails
                            _setImageCompressionProcessList(function (prevImageCompressionProcessList) {
                                var _a;
                                return __assign(__assign({}, prevImageCompressionProcessList), (_a = {}, _a[file.finalName] = value, _a));
                            });
                        },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, imageCompression(imageFile, options)];
                case 2:
                    compressedFile = _a.sent();
                    return [2 /*return*/, new File([compressedFile], file.name)];
                case 3:
                    error_1 = _a.sent();
                    console.log('IMAGE COMPRESSION', error_1);
                    return [2 /*return*/, file];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var moveThumbnails = useCallback(function (dragIndex, hoverIndex) {
        var draggedFile = uploadedAnswerFiles[dragIndex];
        var fileList = __spreadArrays(uploadedAnswerFiles);
        //delete from original index
        fileList.splice(dragIndex, 1);
        //copy to another index
        fileList.splice(hoverIndex, 0, draggedFile);
        setUploadedAnswerFiles(fileList);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadedAnswerFiles]);
    var changePreviewFile = function (file, index) {
        setPreviewFile({
            file: file,
            index: index,
        });
    };
    var updatePreviewFile = function (index) {
        var listOfFiles = __spreadArrays(uploadedAnswerFiles);
        setRecentDeletedIndex(-1);
        if (previewFile.index === -1 || previewFile.index === index) {
            //set first file as preview image object if the current preview image is deleted
            if (previewFile.index === 0 && index === 0 && listOfFiles.length > 1) {
                setPreviewFile({
                    file: listOfFiles[index],
                    index: index,
                });
            } //set previous file as preview image object if the current preview image is deleted
            else if (listOfFiles.length > 1 && listOfFiles[index - 1]) {
                setPreviewFile({
                    file: listOfFiles[index - 1],
                    index: index - 1,
                });
            }
            else if (listOfFiles.length <= 1) {
                setPreviewFile({
                    file: listOfFiles[0],
                    index: 0,
                });
            }
            else {
                setPreviewFile({
                    file: null,
                    index: -1,
                });
            }
        }
    };
    var updateFiles = function (rotatedFile) {
        var uploadTempFile = __spreadArrays(uploadedAnswerFiles);
        var indexToBeReplaced = uploadTempFile.findIndex(function (file) { return file.finalName === rotatedFile.finalName; });
        uploadTempFile.splice(indexToBeReplaced, 1, rotatedFile);
        setUploadedAnswerFiles(uploadTempFile);
    };
    var handleAddFiles = function (uploadedFiles, fileList) { return __awaiter(void 0, void 0, void 0, function () {
        var finalUploadedFiles, _loop_1, i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    finalUploadedFiles = __spreadArrays(uploadedFiles);
                    finalUploadedFiles.forEach(function (file, index) {
                        var fileName = file.name.substr(0, file.name.lastIndexOf('.'));
                        var fileExt = file.name.split('.').pop();
                        var fileNum = _originalImageList.length + index;
                        file.finalName = fileName + "_" + fileNum + "." + fileExt;
                        file.fileExt = fileExt || '';
                        file.fileName = fileName || '';
                        file.isImage = isImageFile(fileExt || '');
                    });
                    setRecentDeletedIndex(-1);
                    if (_originalImageList.length === 0) {
                        _setOriginalImageList(finalUploadedFiles);
                    }
                    else {
                        _setOriginalImageList(__spreadArrays(_originalImageList, finalUploadedFiles));
                    }
                    if (finalUploadedFiles.length > allowedMaxFiles ||
                        _originalImageList.length > allowedMaxFiles ||
                        finalUploadedFiles.length + _originalImageList.length > allowedMaxFiles) {
                        setErrorMessage("The number of files should not exceed " + allowedMaxFiles + ".");
                    }
                    setIsAddingFiles(true);
                    _loop_1 = function (i) {
                        var tempFile, tempCompressFile;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    tempFile = finalUploadedFiles[i];
                                    if (i === 0 && compiledFilename === '') {
                                        setCompiledFilename(uploadedFiles[0].name.substr(0, uploadedFiles[0].name.lastIndexOf('.')));
                                    }
                                    tempCompressFile = null;
                                    if (!isImageFile(tempFile.fileExt)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, compressImage(tempFile)];
                                case 1:
                                    // compress one image at a time
                                    tempCompressFile = _a.sent();
                                    // create preview image object
                                    tempCompressFile.preview = URL.createObjectURL(tempCompressFile);
                                    // reset properties for new compressed image
                                    tempCompressFile.finalName = tempFile.finalName;
                                    tempCompressFile.isImage = true;
                                    tempCompressFile.fileExt = tempFile.fileExt;
                                    tempCompressFile.fileName = tempFile.fileName;
                                    return [3 /*break*/, 3];
                                case 2:
                                    tempCompressFile = tempFile;
                                    _a.label = 3;
                                case 3:
                                    // set default preview file
                                    if ((i === 0 && previewFile.file === null) || (fileList.length === 0 && i === 0)) {
                                        setPreviewFile({ file: tempCompressFile, index: 0 });
                                    }
                                    //TODO: sonar Define this function outside of a loop.(No changes)
                                    setUploadedAnswerFiles(function (prevUploadedFiles) {
                                        var tempArray = __spreadArrays(prevUploadedFiles);
                                        tempArray.push(tempCompressFile);
                                        return tempArray;
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < finalUploadedFiles.length)) return [3 /*break*/, 4];
                    return [5 /*yield**/, _loop_1(i)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    setIsAddingFiles(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validateFile = function (file) {
        var _a;
        var filename = file.name;
        var fileExt = (_a = filename.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (supportedExtensions.includes(fileExt)) {
            return true;
        }
        if (supportedExtensions === null || supportedExtensions === void 0 ? void 0 : supportedExtensions[0]) {
            showError("Please select " + supportedExtensions.join(', ') + " files only", false);
        }
        else {
            showError("Files not supported.", false);
        }
        return false;
    };
    var handleCancel = function () {
        if ((uploadedAnswerFiles === null || uploadedAnswerFiles === void 0 ? void 0 : uploadedAnswerFiles[0]) && confirmationOnCancel) {
            openConfirmationModal('Confirmation', "Are you sure you want to cancel submission?", function () {
                resetState();
                props.toggleShow(false);
            });
        }
        else {
            resetState();
            props.toggleShow(false);
        }
    };
    var resetState = function () {
        _setImageCompressionProcessList({});
        setUploadedAnswerFiles([]);
        _setOriginalImageList([]);
        setPreviewFile({
            file: null,
            index: -1,
        });
        setErrorMessage('');
        setCompiledFilename('');
    };
    var handleDelete = function (index, fileKey) {
        setRecentDeletedIndex(index);
        var listOfFiles = __spreadArrays(uploadedAnswerFiles);
        listOfFiles.splice(index, 1);
        var compressedFilesObject = __assign({}, _imageCompressionProcessList);
        delete compressedFilesObject[fileKey];
        _setImageCompressionProcessList(compressedFilesObject);
        setUploadedAnswerFiles(listOfFiles);
    };
    var handlePageReload = function (event) {
        // Cancel the event
        event.preventDefault(); //prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        event.returnValue = '';
    };
    useEffect(function () {
        //update originalImageList when uploadedAnswerFiles is updated
        if (!isAddingFiles) {
            _setOriginalImageList(uploadedAnswerFiles);
            if (uploadedAnswerFiles.length > allowedMaxFiles) {
                setErrorMessage("The number of files should not exceed " + allowedMaxFiles + ".");
            }
            else {
                setErrorMessage('');
            }
        }
        if (uploadedAnswerFiles.length === 0) {
            setErrorMessage('');
            setCompiledFilename('');
        }
        //eslint-disable-next-line
    }, [uploadedAnswerFiles]);
    useEffect(function () {
        if (recentDeletedIndex > -1) {
            updatePreviewFile(recentDeletedIndex);
        }
    }, 
    //eslint-disable-next-line
    [uploadedAnswerFiles, recentDeletedIndex]);
    useEffect(function () {
        if (uploadedAnswerFiles.length > 0) {
            window.addEventListener('beforeunload', handlePageReload);
        }
        return function () {
            window.removeEventListener('beforeunload', handlePageReload);
        };
    }, [uploadedAnswerFiles]);
    return (React.createElement(React.Fragment, null,
        !props.show && (React.createElement("input", { ref: imageUploadRef, type: "file", id: "upload-image", accept: supportedExtensions.map(function (ext) { return '.' + ext; }).join(', '), style: { display: 'none' }, onClick: function (event) {
                event.target.value = '';
            }, onChange: function (event) { return __awaiter(void 0, void 0, void 0, function () {
                var iterableFiles, uploadedFiles, i;
                return __generator(this, function (_a) {
                    if (event.currentTarget.files) {
                        iterableFiles = event.currentTarget.files;
                        uploadedFiles = Array.from(iterableFiles);
                        for (i = 0; i < uploadedFiles.length; i++) {
                            if (!validateFile(uploadedFiles[i])) {
                                uploadedFiles.splice(i, 1);
                                i--;
                            }
                        }
                        if (uploadedFiles.length > 0) {
                            props.toggleShow(true);
                            handleAddFiles(uploadedFiles, []);
                        }
                    }
                    return [2 /*return*/];
                });
            }); }, multiple: true })),
        props.show && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "bg-white width-100" },
                React.createElement("div", { className: "d-flex justify-content-between flex-row-reverse px-4 py-3" },
                    React.createElement("div", null,
                        React.createElement("button", { className: "btn btn-md text-uppercase", onClick: function () { return handleCancel(); }, disabled: isAddingFiles }, "Cancel"),
                        React.createElement("button", { type: "submit", className: "btn btn-md btn-success text-uppercase", onClick: function () { return handleSubmit(); }, disabled: uploadedAnswerFiles.length === 0 ||
                                compiledFilename === '' ||
                                errorMessage !== '' ||
                                isAddingFiles ||
                                isFileGreaterThan }, "Submit")),
                    isFileGreaterThan && React.createElement("span", { className: "default-form-group error" }, "Files size greater than 100 MB."),
                    convertAllImageToPdf && (React.createElement("div", null,
                        React.createElement("span", { className: "font-weight-semibold mr-2" }, "Compiled File Name:"),
                        ' ',
                        React.createElement(FTooltip, { position: "bottom", tooltipMessage: 'Rename' },
                            React.createElement("input", { type: "text", className: "filename", onChange: function (e) { return setCompiledFilename(e.currentTarget.value); }, value: compiledFilename })),
                        compiledFilename !== '' || uploadedAnswerFiles.length === 0 ? (React.createElement("span", null, " .pdf")) : (React.createElement("span", { className: "default-form-group error" }, " Required."))))),
                errorMessage && React.createElement("div", { className: "default-form-group error px-4 py-3" },
                    " ",
                    errorMessage),
                React.createElement("div", { className: "px-4" },
                    React.createElement("div", { className: 'submission border' },
                        React.createElement("div", { className: 'submission-left uploading-images scrollbar' },
                            React.createElement("div", { className: "files-tabs" },
                                React.createElement(Thumbnails, { moveCard: moveThumbnails, onDelete: handleDelete, onPreview: changePreviewFile, previewFile: previewFile, compressedFileList: uploadedAnswerFiles, isAddingFiles: isAddingFiles, imageCompressionProcessList: _imageCompressionProcessList, fileList: _originalImageList }),
                                React.createElement("div", { className: "px-2" },
                                    React.createElement("div", { className: "upload-area bg-white mt-4 mb-3 p-0 " + (isAddingFiles ? 'disabled' : '') },
                                        React.createElement("div", { className: "hold py-2" },
                                            React.createElement("div", { className: "text-block", "data-testid": "upload-description" },
                                                React.createElement("span", { className: "title" },
                                                    React.createElement("b", null, "Drag and drop or "),
                                                    React.createElement("span", { className: 'text-success' }, "BROWSE")),
                                                React.createElement("small", { className: "text-muted" },
                                                    "upload ",
                                                    supportedExtensions.join(', '),
                                                    " files only. ", "(Max files size " + 50 + " MB)")),
                                            React.createElement("input", { type: "file", id: "files", className: "upload-file", accept: supportedExtensions.map(function (ext) { return '.' + ext; }).join(', '), onClick: function (event) {
                                                    event.target.value = '';
                                                }, onDragEnter: function (event) {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }, onDragOver: function (event) {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }, onDrop: function (event) {
                                                    event.preventDefault();
                                                    var uploadedFiles = [];
                                                    var fileList = __spreadArrays(uploadedAnswerFiles);
                                                    if (event.dataTransfer.items) {
                                                        // Use DataTransferItemList interface to access the file(s)
                                                        for (var i = 0; i < event.dataTransfer.items.length; i++) {
                                                            // If dropped items aren't files, reject them
                                                            if (event.dataTransfer.items[i].kind === 'file') {
                                                                var file = event.dataTransfer.items[i].getAsFile();
                                                                if (file && validateFile(file)) {
                                                                    uploadedFiles.push(file);
                                                                }
                                                            }
                                                        }
                                                    }
                                                    handleAddFiles(uploadedFiles, fileList);
                                                }, onChange: function (event) { return __awaiter(void 0, void 0, void 0, function () {
                                                    var iterableFiles, uploadedFiles, i, fileList;
                                                    return __generator(this, function (_a) {
                                                        if (event.currentTarget.files) {
                                                            iterableFiles = event.currentTarget.files;
                                                            uploadedFiles = Array.from(iterableFiles);
                                                            for (i = 0; i < uploadedFiles.length; i++) {
                                                                if (!validateFile(uploadedFiles[i])) {
                                                                    uploadedFiles.splice(i, 1);
                                                                    i--;
                                                                }
                                                            }
                                                            fileList = __spreadArrays(uploadedAnswerFiles);
                                                            handleAddFiles(uploadedFiles, fileList);
                                                        }
                                                        return [2 /*return*/];
                                                    });
                                                }); }, multiple: true, disabled: isAddingFiles })))))),
                        React.createElement("div", { className: 'submission-right preview-image' }, previewFile && previewFile.file !== null && uploadedAnswerFiles.length > 0 && (React.createElement(ImagePreview, { previewFile: previewFile.file, onRotate: updateFiles, disabled: isAddingFiles })))))),
            // check formData to enable redirect if the error status code is redirect
            uploadedAnswerFiles.length > 0 && (React.createElement(Prompt, { when: uploadedAnswerFiles.length > 0, message: "The data will not be saved. Are you sure you want to proceed?" })))),
        confirmationModal));
};
export default UploadFileWithPreview;
