import { schema } from 'normalizr';
// Application Form
export var application = new schema.Entity('application');
export var applicationBatchDetails = new schema.Entity('application', {}, { idAttribute: 'batchId' });
// Program Coordinator
// export const program = new schema.Entity('program', {}, { idAttribute: 'programId' });
export var course = new schema.Entity('course', {}, { idAttribute: 'courseId' });
// export const batches = new schema.Entity('batches', {}, { idAttribute: 'batchId' });
export var batchStudents = new schema.Entity('batchStudents');
// Super Admin
export var admins = new schema.Entity('admins', {}, { idAttribute: 'id' });
