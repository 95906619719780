var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect, useRef } from 'react';
import { GetStorageComponent, SetStorageComponent, removeCognitoSession } from '@fuse/utils/dist/session';
import { CognitoAuth } from '@fuse/config/dist/Cognito';
import { USER_INFO, ACCESS_TOKEN, SERVER_UNAVAILABLE, UNAUTHORIZED, SCHOOL_FORBIDDEN, SESSION_EXPIRED, SCHOOL_TERMINATED, } from '@fuse/messages/dist/common';
import { fetchUserInfo } from '@fuse/services/dist/Users';
import { showError } from '@fuse/utils/dist/alertNotification';
import { getErrorMessage } from '@fuse/services/dist/Axios';
import { useRouter } from '@fuse/utils/dist/useRouter';
import { LOGIN_ROUTE } from '../../constants';
import { getRegistry } from '@fuse/services/dist/Registry';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_USER_INFO } from '../../Reducer/Session';
import { FETCH_APP_LIST } from '../../Reducer/HomeRegistry';
var ERRORS = [SERVER_UNAVAILABLE, UNAUTHORIZED, SCHOOL_FORBIDDEN, SESSION_EXPIRED, SCHOOL_TERMINATED];
var useAuth = function () {
    var isMountedRef = useRef(false);
    var dispatch = useDispatch();
    var _a = useRouter(), query = _a.query, push = _a.push;
    var error = query.error;
    var registry = useSelector(function (state) { return state.homeRegistry; });
    var userInfo = useSelector(function (state) { return state.session.user; });
    var accessToken = GetStorageComponent(ACCESS_TOKEN);
    var _b = useState(), isAuthenticated = _b[0], setAuthenticated = _b[1];
    useEffect(function () {
        isMountedRef.current = false;
        var verifyAuthentication = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!accessToken) {
                    logout();
                }
                else if (accessToken && !ERRORS.includes(error)) {
                    if (!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.id) || !registry.id) {
                        getAppInfo();
                    }
                    else {
                        setAuthenticated(true);
                    }
                }
                else {
                    setAuthenticated(true);
                }
                return [2 /*return*/, function () {
                        isMountedRef.current = true;
                    }];
            });
        }); };
        verifyAuthentication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var logout = function () {
        removeCognitoSession();
        dispatch({ type: FETCH_USER_INFO, payload: null });
        CognitoAuth.signOut();
        setAuthenticated(false);
        push(LOGIN_ROUTE);
    };
    var getAppInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, userRes, registryRes, _b, _c, _d, error_1;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 4, , 5]);
                    _c = (_b = Promise).all;
                    return [4 /*yield*/, getUser()];
                case 1:
                    _d = [_e.sent()];
                    return [4 /*yield*/, getRegistryApps()];
                case 2: return [4 /*yield*/, _c.apply(_b, [_d.concat([_e.sent()])])];
                case 3:
                    _a = _e.sent(), userRes = _a[0], registryRes = _a[1];
                    if (userRes.data && !isMountedRef.current) {
                        dispatch({ type: FETCH_USER_INFO, payload: userRes.data });
                        dispatch({ type: FETCH_APP_LIST, payload: registryRes.data });
                        SetStorageComponent(USER_INFO, JSON.stringify(userRes.data));
                        setAuthenticated(true);
                    }
                    else {
                        setAuthenticated(false);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _e.sent();
                    showError(getErrorMessage(error_1));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (userInfo.id) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return resolve({ data: userInfo }); })];
            }
            return [2 /*return*/, fetchUserInfo()];
        });
    }); };
    var getRegistryApps = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (registry.id) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return resolve({ data: registry }); })];
            }
            return [2 /*return*/, getRegistry()];
        });
    }); };
    var isAuthorized = function (roles) {
        return roles === null || roles === void 0 ? void 0 : roles.some(function (role) { var _a; return (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.authorities) === null || _a === void 0 ? void 0 : _a.includes(role); });
    };
    return { isAuthenticated: isAuthenticated, userInfo: userInfo, logout: logout, isAuthorized: isAuthorized };
};
export default useAuth;
