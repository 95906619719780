var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field } from 'react-final-form';
export var FinalTextAreaInput = function (props) {
    var legend = props.legend, name = props.name, formProps = props.formProps, required = props.required, disabled = props.disabled, validate = props.validate, value = props.value, inputClassName = props.inputClassName, className = props.className, requirementMsg = props.requirementMsg, rows = props.rows, cols = props.cols, placeholder = props.placeholder;
    var isRequired = function (valueData) { return (valueData && valueData.toString().trim() ? undefined : 'Required'); };
    return (React.createElement(Field, { name: name, "form-groupform-group": true, validate: validate ? validate : !required ? undefined : isRequired }, function (_a) {
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: (className ? className : 'form-group') + " " + (meta.error && (meta.dirty || meta.submitFailed) && meta.touched ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: "" },
                legend,
                (required || validate) && legend ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement("div", null,
                React.createElement("textarea", __assign({}, input, { value: value ? value : formProps.values[name] || formProps.values[name] === 0 ? formProps.values[name] : '', className: "form-control " + (inputClassName ? inputClassName : ''), disabled: disabled, onChange: function (e) {
                        formProps.form.change(name, e.target.value);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }, rows: rows || 6, cols: cols || 50, placeholder: placeholder || '' })),
                (meta.error && (meta.dirty || meta.submitFailed) && meta.touched) || requirementMsg ? (React.createElement("span", { className: "required" }, requirementMsg ? requirementMsg : meta.error)) : null)));
    }));
};
