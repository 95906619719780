var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useEffect, useState } from 'react';
var ImagePreview = function (_a) {
    var previewFile = _a.previewFile, onRotate = _a.onRotate, disabled = _a.disabled;
    var _b = useState({
        rotate: null,
        isSaving: false,
    }), editData = _b[0], setEditData = _b[1];
    var canvasRef = useRef(null);
    var imageRef = useRef(null);
    useEffect(function () {
        if (imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) {
            var canvas = canvasRef.current;
            var context = canvas.getContext('2d');
            var image = imageRef.current;
            drawImageInCanvas(context, image, canvas, editData.rotate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (previewFile.isImage) {
            var canvas_1 = canvasRef.current;
            var context_1 = canvas_1.getContext('2d');
            var image_1 = imageRef.current;
            image_1.onload = function () {
                drawImageInCanvas(context_1, image_1, canvas_1, editData.rotate);
            };
            drawImageInCanvas(context_1, image_1, canvas_1, editData.rotate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editData.rotate, previewFile]);
    var drawImageInCanvas = function (ctx, img, canvas, rotation) {
        var width = img.width;
        var height = img.height;
        if (editData.rotate === 90 || editData.rotate === 270) {
            canvas.width = height;
            canvas.height = width;
        }
        else {
            canvas.width = width;
            canvas.height = height;
        }
        ctx.clearRect(0, 0, width, height);
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        if (rotation !== 0) {
            ctx.rotate((rotation * Math.PI) / 180);
        }
        ctx.drawImage(img, -width / 2, -height / 2, width, height);
        ctx.restore();
    };
    //reset rotation when changing preview images
    useEffect(function () {
        if (typeof editData.rotate === 'number') {
            setEditData(__assign(__assign({}, editData), { rotate: null }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewFile]);
    useEffect(function () {
        if (typeof editData.rotate === 'number' && (canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current)) {
            var canvas = canvasRef.current;
            saveRotation(canvas);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editData.rotate]);
    var saveRotation = function (canvas) {
        setEditData(__assign(__assign({}, editData), { isSaving: true }));
        canvas.toBlob(function (blob) {
            var rotatedFile = new File([blob], previewFile.name, { type: 'image/' + previewFile.fileExt });
            // reset file properties for new image
            rotatedFile.finalName = previewFile.finalName;
            rotatedFile.fileName = previewFile.fileName;
            rotatedFile.fileExt = previewFile.fileExt;
            rotatedFile.isImage = true;
            rotatedFile.preview = URL.createObjectURL(rotatedFile);
            onRotate(rotatedFile);
            setEditData(__assign(__assign({}, editData), { isSaving: false }));
        });
    };
    var rotateImage = function (direction) {
        var newRotation = editData.rotate || 0 + 360;
        if (direction === 'CLOCKWISE') {
            newRotation = newRotation + 90;
        }
        else {
            newRotation = newRotation - 90;
        }
        setEditData(__assign(__assign({}, editData), { rotate: newRotation % 360 }));
    };
    return (React.createElement(React.Fragment, null,
        previewFile.isImage && (React.createElement("div", { className: "bg-gray-300 d-flex justify-content-between align-items-center px-1 py-2 text-right mb-4" },
            React.createElement("div", { className: "ml-3" },
                disabled && React.createElement("div", { className: "text-muted " }, "Files are being compressed. Please wait.."),
                editData.isSaving && React.createElement("span", { className: "text-muted" }, "Saving...")),
            React.createElement("div", { className: "d-flex align-items-end" },
                React.createElement("button", { className: "mr-2 preview-buttons", onClick: function () { return rotateImage('ANTI_CLOCKWISE'); }, disabled: editData.isSaving || disabled },
                    React.createElement("span", { className: "icon-reload  icn-sm  reload-reflect font-weight-semibold" })),
                React.createElement("button", { className: "mr-2 preview-buttons", onClick: function () { return rotateImage('CLOCKWISE'); }, disabled: editData.isSaving || disabled },
                    React.createElement("span", { className: "icon-reload  icn-sm font-weight-semibold" }))))),
        React.createElement("div", { className: "preview" }, previewFile.isImage ? (React.createElement("canvas", { ref: canvasRef, className: "area" },
            React.createElement("img", { ref: imageRef, src: previewFile.preview, alt: "", style: { display: 'none' } }))) : (React.createElement("div", { className: "d-flex justify-content-center align-items-center" },
            React.createElement("span", { className: "font-weight-semibold" }, "No preview available."))))));
};
export default ImagePreview;
