import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
export var FSearch = function (props) {
    var placeholder = props.placeholder, isLoading = props.isLoading, searchText = props.searchText, divClassName = props.divClassName;
    var _a = useState(''), _searchText = _a[0], _setSearchText = _a[1];
    useEffect(function () {
        if (searchText || searchText === '') {
            _setSearchText(searchText || '');
        }
    }, [searchText]);
    return (React.createElement("div", { className: "search-keyword " + (divClassName ? divClassName : '') },
        React.createElement(InputGroup, { className: "search" },
            React.createElement(InputGroup.Prepend, null,
                React.createElement(InputGroup.Text, { className: "border-0 bg-white ml-1" },
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20" },
                        React.createElement("path", { id: "Path_24010", "data-name": "Path 24010", d: "M23.734,22.448l-5.159-5.159a8.183,8.183,0,1,0-1.286,1.285l5.159,5.159a.909.909,0,0,0,1.286-1.285Zm-11.552-3.9a6.364,6.364,0,1,1,6.364-6.364A6.364,6.364,0,0,1,12.182,18.545Z", transform: "translate(-4 -4)", fill: "#c7c7c7" })))),
            React.createElement(FormControl, { disabled: isLoading ? isLoading : false, placeholder: placeholder, "aria-label": "search", className: "border-0", "aria-describedby": "basic-addon1", onChange: function (e) {
                    _setSearchText(e.target.value);
                    if (props.onSearch && e.target.value.trim() === '' && searchText !== e.target.value.trim()) {
                        props.onSearch(e.target.value.trim());
                    }
                }, onKeyPress: function (e) {
                    if (props.onSearch) {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            props.onSearch(_searchText.trim());
                        }
                    }
                }, value: _searchText }))));
};
