import React, { useState } from 'react';
export var FTab = function (props) {
    var tabList = props.tabList, activeTab = props.activeTab, navClassName = props.navClassName;
    var _a = useState(tabList && tabList.length > 0 ? tabList[0].id : ''), active = _a[0], setActive = _a[1];
    var onChangeTab = function (tab) {
        props.onChangeTab(tab);
        setActive(tab.id);
    };
    var tabs = tabList.map(function (tab, index) {
        var className = tab.id === activeTab || tab.label === activeTab ? 'active' : tab.id === active ? "active-tab" : "";
        return (React.createElement("li", { key: index, className: "nav-item " + className, onClick: function () { return onChangeTab(tab); } },
            React.createElement("button", { className: "btn btn-link nav-link", id: tab.id + "-tab", "data-toggle": "tab", role: "tab", "aria-controls": tab.id, "aria-selected": "true" }, tab.label)));
    });
    return (React.createElement("div", { className: "fuse-tabs" },
        React.createElement("ul", { className: "nav nav-tabs " + navClassName, id: "myTab", role: "tablist" }, tabs)));
};
