var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
/**
 *  Return memoized function with { push, replace, pathname, query(params), match, location, history}
 */
export var useRouter = function () {
    var params = useParams();
    var location = useLocation();
    var history = useHistory();
    var match = useRouteMatch();
    return useMemo(function () {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: __assign(__assign({}, getQueryStringParams(location.search)), params),
            params: params,
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match: match,
            location: location,
            history: history,
        };
    }, [params, match, location, history]);
};
export var getQueryStringParams = function (query) {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce(function (params, param) {
            var _a = param.split('='), key = _a[0], value = _a[1];
            try {
                var tempValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                // for array query params
                if (tempValue.split(',').length > 1) {
                    params[key] = tempValue.split(',');
                }
                else {
                    params[key] = tempValue;
                }
                return params;
            }
            catch (error) {
                // URIError: URI malformed for query param like ?keyword=%&
                params[key] = value;
                return params;
            }
        }, {})
        : {};
};
export var urlConcatIds = function (url, programId, batchId, courseId, unitId) {
    return url + "?programId=" + programId + "&batchId=" + batchId + "&courseId=" + courseId + "&unitId=" + unitId;
};
