var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Field } from 'react-final-form';
export var FinalSelect = function (props) {
    var legend = props.legend, name = props.name, formProps = props.formProps, required = props.required, validate = props.validate, options = props.options, className = props.className, errorClass = props.errorClass, disabled = props.disabled, value = props.value, isOptionsLoading = props.isOptionsLoading;
    var isRequired = function (valueItem) { return (valueItem && valueItem.toString().trim() ? undefined : 'Required'); };
    var optionList = options.map(function (option, index) {
        return (React.createElement("option", { key: index, value: option.value, disabled: option.isDisabled }, option.label));
    });
    return (React.createElement(Field, { name: name, validate: validate ? validate : !required ? undefined : isRequired }, function (_a) {
        var input = _a.input, meta = _a.meta;
        return (React.createElement("div", { className: (className ? className : 'form-group') + " " + (meta.error && meta.touched ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: "" },
                legend,
                required || validate ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement("div", { className: "search-status" },
                React.createElement("select", __assign({ className: "form-control " + (required ? 'required' : '') }, input, { disabled: disabled || optionList.length === 0 || (optionList.length === 1 && required), value: value ? value : formProps && formProps.values[name], onChange: function (e) {
                        if (formProps) {
                            formProps.form.change(name, e.target.value);
                        }
                        if (formProps && props.onChange) {
                            props.onChange(e, formProps);
                        }
                        else if (props.onChange) {
                            props.onChange(e);
                        }
                    } }), isOptionsLoading ? React.createElement("option", { value: 'Loading' }, 'Loading...') : optionList),
                meta.error && meta.touched && React.createElement("span", { className: errorClass || 'required' }, meta.error))));
    }));
};
// export default FinalSelect;
/* import * as React from 'react';
import { Field } from 'react-final-form';
import { IOptions } from '../Types';

interface FinalSelectProps {
  legend?: string;
  name: string;
  children?: React.ReactNode;
  className?: string;
  formProps?: any;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  validate?: (value: any) => void;
  options: IOptions[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, formProps?: any) => void;
  errorClass?: string;
}

export const FinalSelect = (props: FinalSelectProps) => {
  const { legend, name, formProps, required, validate, options, className, errorClass, disabled } = props;
  const isRequired = (value: any) => (value && value.toString().trim() ? undefined : 'Required');
  const optionList = options.map((option: IOptions, index: number) => {
    return (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    );
  });
  return (
    <Field name={name} validate={validate ? validate : !required ? undefined : isRequired}>
      {({ input, meta }) => (
        <div className={`${className ? className : 'form-group'} ${meta.error && meta.touched ? 'error' : ''}`}>
          {legend ? (
            <label htmlFor="">
              {legend}
              {required || validate ? <span className="astrik">*</span> : ''}
            </label>
          ) : null}
          <div className={`search-status`}>
            <select
              className="form-control"
              {...input}
              disabled={disabled || optionList.length === 0}
              value={formProps && formProps.values[name]}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (formProps) {
                  formProps.form.change(name, e.target.value);
                }
                if (formProps && props.onChange) {
                  props.onChange(e, formProps);
                } else if (props.onChange) {
                  props.onChange(e);
                }
              }}
            >
              {optionList}
            </select>
            {meta.error && meta.touched && <span className={errorClass || 'required'}>{meta.error}</span>}
          </div>
        </div>
      )}
    </Field>
  );
};
 */
