var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; //editor css
// import '../../../../../node_modules//react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // TODO: uncomment
import { getBase64 } from '@fuse/utils/dist/fileUtils';
import ErrorBoundary from './error';
var TextEditor = /** @class */ (function (_super) {
    __extends(TextEditor, _super);
    function TextEditor(props) {
        var _this = _super.call(this, props) || this;
        _this.getRef = function (ref) { };
        _this.changeState = function (value, shouldNotify) {
            var editorState = EditorState.createEmpty();
            try {
                if (value && value.length) {
                    var html = value;
                    var contentBlock = htmlToDraft(html);
                    if (contentBlock && contentBlock.contentBlocks) {
                        var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        editorState = EditorState.createWithContent(contentState);
                    }
                }
            }
            catch (err) {
                // console.log(err);
            }
            if (shouldNotify) {
                _this.setState({ editorState: editorState }, function () { return _this.onEditorStateChange(editorState); });
            }
            else {
                _this.setState({ editorState: editorState });
            }
        };
        _this.onEditorStateChange = function (editorState) {
            try {
                var data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                if (_this.props.setFieldValue) {
                    _this.props.setFieldValue(_this.props.fieldName, data);
                }
                // *need to refactor
                // ! not good
                if (_this.props.passEditorData) {
                    if (data.length !== 8) {
                        _this.props.passEditorData(data);
                    }
                    else {
                        _this.props.passEditorData('');
                    }
                }
                // *need to refactor
                // ! not good
                if (_this.props.change) {
                    if (data.length !== 8) {
                        _this.props.change(_this.props.fieldName, data);
                    }
                    else {
                        _this.props.change(_this.props.fieldName, '');
                    }
                }
                _this.setState({
                    editorState: editorState,
                });
            }
            catch (err) {
                // console.log(err);
            }
        };
        _this.uploadImageCallBack = function (file) { return __awaiter(_this, void 0, void 0, function () {
            var uploadedImages, imageObject;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uploadedImages = this.state.uploadedImages;
                        imageObject = {
                            file: file,
                            localSrc: '',
                        };
                        return [4 /*yield*/, getBase64(file)
                                .then(function (res) {
                                imageObject.localSrc = res;
                            })
                                .catch(function (err) {
                                // console.log(err);
                            })];
                    case 1:
                        _a.sent();
                        uploadedImages.push(imageObject);
                        this.setState({ uploadedImages: uploadedImages });
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                resolve({ data: { link: imageObject.localSrc } });
                            })];
                }
            });
        }); };
        // When link is added inside the editor and if the link is clicked by default the editor opens the link
        // on a new tab even if the "open link in new window" checkbox is not checked
        // This function opens the link respects to aTag.target
        // aTag.target can be "_self" or "_blank"
        _this.openLinkInsideEditor = function () {
            var _a;
            var parentNode = (_a = document.getElementsByClassName('rdw-link-decorator-wrapper')) === null || _a === void 0 ? void 0 : _a[0];
            if (parentNode) {
                var aTag_1 = parentNode.children[0];
                parentNode.onmouseenter = function () {
                    var imgTag = parentNode.children[1];
                    imgTag === null || imgTag === void 0 ? void 0 : imgTag.addEventListener('click', function () {
                        var url = aTag_1.href;
                        window.open(url, aTag_1.target);
                    });
                };
            }
        };
        _this.state = {
            editorState: '',
            uploadedImages: [],
        };
        return _this;
    }
    TextEditor.prototype.componentWillMount = function () {
        this.changeState(this.props.value);
    };
    TextEditor.prototype.componentDidMount = function () {
        this.openLinkInsideEditor();
    };
    TextEditor.prototype.render = function () {
        var _this = this;
        var editorState = this.state.editorState;
        return (React.createElement("div", null,
            React.createElement(ErrorBoundary, { changeState: this.changeState },
                React.createElement(Editor, { editorState: editorState, wrapperClassName: "wrapper-class", editorClassName: "editor-class", editorRef: this.props.getEditorRefrence ? this.props.getEditorRefrence : this.getRef, toolbarClassName: "toolbar-class border-bottom", onEditorStateChange: this.onEditorStateChange, toolbar: {
                        fontFamily: {
                            options: ['Source Sans Pro'],
                        },
                        // inline: { inDropdown: true },
                        // list: { inDropdown: true },
                        // textAlign: { inDropdown: true },
                        options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'embedded',
                            'emoji',
                            'remove',
                            'history',
                        ],
                        link: {
                            // popupClassName: 'editor-modal',
                            defaultTargetOption: '_self',
                            linkCallback: function (linkCallbackParams) {
                                setTimeout(function () {
                                    _this.openLinkInsideEditor();
                                }, 10);
                                return linkCallbackParams;
                            },
                        },
                        colorPicker: {
                            popupClassName: 'editor-modal',
                        },
                        emoji: {
                            popupClassName: 'editor-modal',
                        },
                        image: {
                            className: undefined,
                            component: undefined,
                            popupClassName: 'editor-modal',
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: false,
                            uploadCallback: this.uploadImageCallBack,
                            previewImage: true,
                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            alt: { present: false, mandatory: false },
                            defaultSize: {
                                height: '100',
                                width: '100',
                            },
                        },
                        embedded: {
                            popupClassName: 'editor-modal',
                        },
                    } }))));
    };
    return TextEditor;
}(React.Component));
export default TextEditor;
