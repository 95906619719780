var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { FModal } from '../FModal';
export var useModal = function (props) {
    if (props === void 0) { props = {}; }
    var _a = useState({
        show: false,
        title: '',
        body: '',
        modalProps: {},
    }), _modalData = _a[0], _setModalData = _a[1];
    var openModal = function (title, body, modalProps) {
        if (modalProps === void 0) { modalProps = {}; }
        _setModalData({ show: true, title: title, body: body, modalProps: modalProps });
    };
    var closeModal = function () {
        _setModalData({
            show: false,
            title: '',
            body: '',
            modalProps: {},
        });
    };
    var modal = (React.createElement(FModal, __assign({}, __assign(__assign({}, props), _modalData.modalProps), { className: "resource-modal", modal: _modalData.show, toggleModal: function () {
            _setModalData(__assign(__assign({}, _modalData), { show: !_modalData.show }));
        }, title: _modalData.title }),
        React.createElement(React.Fragment, null, _modalData.body)));
    return {
        openModal: openModal,
        closeModal: closeModal,
        modal: modal,
    };
};
