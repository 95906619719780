var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { FModal } from '../FModal';
/**
 * HOW to use?
 *
 * import useConfirmationModal from './here' \n
 *
 * const func = () => {
 *
 *  let { openConfirmationModal,confirmationModal }  = useConfirmationModal()
 *
 *  return(
 *
 *   <>
 *
 *     <button onClick={() => {
 *        openConfirmationModal('title','subtitle, () => {alert(keep your after confirmation task)});
 *
 *     }} />
 *
 *     <button2 onClick={() => {
 *       openConfirmationModal('title2','subtitle2, () => {alert(keep your after confirmation task2)});
 *
 *     }} />
 *
 *      {confirmationModal}
 *
 *   </>
 *  )
 * }
 */
export var useConfirmationModal = function () {
    var _a = useState({
        show: false,
        title: '',
        subTitle: '',
        confirmLabel: '',
        confirmButtonType: 'success',
        body: '',
        hideCancel: false,
    }), _modalData = _a[0], _setModalData = _a[1];
    var _b = useState(false), _disableConfirm = _b[0], _setDisableConfirm = _b[1];
    var openConfirmation = function (title, subTitle, onConfirm, confirmButtonType, onCancel, confirmLabel, body, hideCancel) {
        if (hideCancel === void 0) { hideCancel = false; }
        _setModalData({
            show: true,
            title: title,
            subTitle: subTitle,
            confirmLabel: confirmLabel,
            confirmButtonType: confirmButtonType,
            body: body,
            onConfirm: onConfirm,
            onCancel: onCancel,
            hideCancel: hideCancel,
        });
    };
    var Modal = (React.createElement(FModal, { className: "resource-modal", modal: _modalData.show, toggleModal: function () {
            _setModalData(__assign(__assign({}, _modalData), { show: !_modalData.show }));
        }, title: _modalData.title, disableCloseButton: _modalData.hideCancel },
        React.createElement(React.Fragment, null, _modalData.subTitle && !_modalData.body && (React.createElement(React.Fragment, null,
            React.createElement("h6", { className: "text-center" }, _modalData.subTitle),
            React.createElement("br", null)))),
        _modalData.body ? _modalData.body : null,
        React.createElement("div", { className: "btm-btn text-right" },
            !_modalData.hideCancel && (React.createElement("button", { className: "btn mr-3 px-3", type: "button", onClick: function () {
                    _setModalData(__assign(__assign({}, _modalData), { show: false }));
                    if (_modalData.onCancel) {
                        _modalData.onCancel();
                    }
                } }, "CANCEL")),
            React.createElement("button", { className: "btn px-3 " + (_modalData.confirmButtonType === 'danger' ? 'btn-danger' : 'btn-green'), onClick: function () {
                    _setModalData(__assign(__assign({}, _modalData), { show: false }));
                    _modalData.onConfirm();
                }, disabled: _disableConfirm }, _modalData.confirmLabel ? _modalData.confirmLabel : 'CONFIRM'))));
    return {
        openConfirmationModal: openConfirmation,
        confirmationModal: Modal,
        setDisableConfirm: _setDisableConfirm,
    };
};
