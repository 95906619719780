var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getUserSession } from '@fuse/utils/dist/session';
export var FETCH_USER_INFO = 'FETCH_USER_INFO';
export var SessionStoreState = {
    user: getUserSession(),
};
export default function reducer(state, action) {
    if (state === void 0) { state = SessionStoreState; }
    if ((action === null || action === void 0 ? void 0 : action.type) === FETCH_USER_INFO) {
        return __assign(__assign({}, state), { user: __assign(__assign({}, state), action.payload) });
    }
    return state;
}
