var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from '../useAuth';
import { LoaderWrap } from '@fuse/ui-components/dist/LoaderWrap';
import { AmplifySessionExpired } from '../AmplifySessionExpired';
import Terminate from '../Terminate';
import { useRouter } from '@fuse/utils/dist/useRouter';
import { FButton } from '@fuse/ui-components/dist/FButton';
import { SCHOOL_FORBIDDEN, SERVER_UNAVAILABLE, SCHOOL_TERMINATED, UNAUTHORIZED, SESSION_EXPIRED, } from '@fuse/messages/dist/common';
export var PrivateRoute = function (_a) {
    var Component = _a.component, roles = _a.roles, rest = __rest(_a, ["component", "roles"]);
    var _b = useAuth(), isAuthenticated = _b.isAuthenticated, isAuthorized = _b.isAuthorized;
    var query = useRouter().query;
    var error = query.error;
    if (typeof isAuthenticated !== 'boolean') {
        return React.createElement(LoaderWrap, { isLoading: true, type: "Main" });
    }
    if (error === SCHOOL_TERMINATED) {
        return React.createElement(Terminate, null);
    }
    if (error === SERVER_UNAVAILABLE) {
        return React.createElement(ServerUnavailable, null);
    }
    if (error === SESSION_EXPIRED) {
        return React.createElement(AmplifySessionExpired, null);
    }
    if (isAuthenticated && !isAuthorized(roles)) {
        return React.createElement(UnauthorizedRole, null);
    }
    if (error === SCHOOL_FORBIDDEN) {
        return React.createElement(UnauthorizedSchool, null);
    }
    if (error === UNAUTHORIZED) {
        return React.createElement(Unauthorized, null);
    }
    return (React.createElement(React.Fragment, null, isAuthenticated && isAuthorized(roles) ? React.createElement(Route, __assign({ component: Component }, rest)) : React.createElement(Redirect, { to: "/login" })));
};
var ServerUnavailable = function () {
    var query = useRouter().query;
    var redirect_url = query.redirect_url;
    return (React.createElement("div", null,
        React.createElement("div", { className: "d-flex justify-content-center flex-column align-items-center" },
            React.createElement("h1", null, "503 - Server Unavailable"),
            React.createElement("span", null, "The server is temporarily unavailable, try again later.")),
        React.createElement("div", { className: "server-error" },
            React.createElement(FButton, { className: "btn btn-sm btn-green", label: "Retry", onClick: function () {
                    if (redirect_url) {
                        window.location.replace(redirect_url);
                    }
                    else {
                        window.location.replace('/');
                    }
                } }))));
};
var UnauthorizedRole = function () {
    var logout = useAuth().logout;
    return (React.createElement("div", null,
        React.createElement("div", { className: "d-flex justify-content-center flex-column align-items-center" },
            React.createElement("h1", null, "403 - Unauthorized"),
            React.createElement("span", null, "Your authorization failed."),
            React.createElement("span", null, "User not associate with the role.")),
        React.createElement("div", { className: "server-error" },
            React.createElement(FButton, { className: "btn btn-sm btn-green", label: "Logout", onClick: logout }))));
};
var UnauthorizedSchool = function () {
    var logout = useAuth().logout;
    return (React.createElement("div", null,
        React.createElement("div", { className: "d-flex justify-content-center flex-column align-items-center" },
            React.createElement("h1", null, "403 - Unauthorized"),
            React.createElement("span", null, "Your authorization failed."),
            React.createElement("span", null, "User not associate with the School.")),
        React.createElement("div", { className: "server-error" },
            React.createElement(FButton, { className: "btn btn-sm btn-green", label: "Logout", onClick: logout }))));
};
var Unauthorized = function () {
    var logout = useAuth().logout;
    return (React.createElement("div", null,
        React.createElement("div", { className: "d-flex justify-content-center flex-column align-items-center" },
            React.createElement("h1", null, "403 - Unauthorized"),
            React.createElement("span", null, "Your authorization failed."),
            React.createElement("span", null, "User not associate with this feature.")),
        React.createElement("div", { className: "server-error" },
            React.createElement(FButton, { className: "btn btn-sm btn-green", label: "Logout", onClick: logout }))));
};
export default PrivateRoute;
