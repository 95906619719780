import * as React from 'react';
import { Field } from 'react-final-form';
export var FinalFileUploadInput = function (props) {
    var name = props.name, legend = props.legend, formProps = props.formProps, required = props.required, disabled = props.disabled, validate = props.validate, errorClass = props.errorClass, requirementMsg = props.requirementMsg, className = props.className, multiple = props.multiple, fields = props.fields, supportedFilesList = props.supportedFilesList, maxFileSize = props.maxFileSize, sampleFile = props.sampleFile, imageDimension = props.imageDimension, title = props.title, customError = props.customError, iconSrc = props.iconSrc;
    var isRequired = function (value) { return (value && value.toString().trim() ? undefined : 'Required'); };
    return (React.createElement(Field, { name: name, validate: validate ? validate : !required ? undefined : isRequired }, function (_a) {
        var input = _a.input, meta = _a.meta;
        var hasError = meta.error && (meta.dirty || meta.submitFailed) ? true : false;
        return (React.createElement("div", { className: (className ? className : 'default-form-group ') + " " + (hasError || customError ? 'error' : '') },
            legend ? (React.createElement("label", { htmlFor: "" },
                legend,
                required && legend ? React.createElement("span", { className: "astrik" }, "*") : '')) : null,
            React.createElement("div", null,
                React.createElement("div", { className: "upload-area" },
                    React.createElement("div", { className: "hold" },
                        iconSrc ? (React.createElement("div", { className: "drag-icons my-4" },
                            React.createElement("img", { src: iconSrc, className: "img-fluid mb-2", alt: "upload-photos" }))) : (''),
                        React.createElement("div", { className: "text-block", "data-testid": "upload-description" },
                            React.createElement("span", { className: "title" },
                                React.createElement("b", null, "Drag and drop or "),
                                React.createElement("span", { className: 'text-success' }, "BROWSE"),
                                React.createElement("b", null,
                                    " file ",
                                    title)),
                            React.createElement("small", null,
                                supportedFilesList && React.createElement(React.Fragment, null, supportedFilesList.join(', ') + " files only"),
                                maxFileSize && React.createElement(React.Fragment, null,
                                    " ", "(Max files size " + maxFileSize + " MB)"),
                                imageDimension && React.createElement(React.Fragment, null, " img size " + imageDimension,
                                    ")"))),
                        React.createElement("input", { type: "file", id: "files", accept: "." + (supportedFilesList === null || supportedFilesList === void 0 ? void 0 : supportedFilesList.join(',.')), name: name, className: "upload-file", multiple: multiple ? multiple : false, onChange: function (e) {
                                formProps.form.change(name, e.target.value);
                                if (props.onFileChange) {
                                    props.onFileChange(e, fields);
                                }
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                            }, disabled: disabled, onClick: props.onClick }))),
                (hasError || requirementMsg) && (React.createElement("span", { className: errorClass && !requirementMsg ? errorClass : 'required' }, requirementMsg ? requirementMsg : meta.error))),
            sampleFile && (React.createElement(React.Fragment, null,
                ' ',
                React.createElement("a", { href: sampleFile }, "Download"), " Sample File"))));
    }));
};
