import moment from 'moment';
export var dateFormat = 'll';
export var convertMMMDDYYY = function (date) {
    return moment(date).format(dateFormat);
};
export var convertMMYYY = function (date) {
    if (!date)
        return;
    return moment(moment(date, 'DD-MM-YYYY').toDate()).format('MMM YYYY');
};
export var convertFormat = function (date) {
    if (!date)
        return;
    var m = moment(moment(date, 'DD-MM-YYYY').toDate());
    if (m.isValid()) {
        return moment(moment(moment(date, 'DD-MM-YYYY').toDate()).format('YYYY-MM-DD'));
    }
    else {
        return '';
    }
};
export var matchDate = function (current, seleced) {
    var today = moment(moment(moment(current, 'DD-MM-YYYY').toDate()).format('YYYY-MM-DD')).isSameOrBefore(moment(moment(seleced, 'DD-MM-YYYY').toDate()).format('YYYY-MM-DD'));
    return today;
};
export var validEndDate = function (current, seleced) {
    var today = moment(seleced).isAfter(moment(current));
    return today;
};
export var validStartDate = function (current, seleced) {
    var today = moment(seleced).isSameOrBefore(moment());
    return today;
};
export var convertEpoch = function (date) {
    if (!date)
        return;
    return moment(date).format('DD/MM/YYYY h:mm A');
};
export var convertEpochToDate = function (date, format) {
    if (!date)
        return;
    return moment(date).format(format || 'DD-MM-YYYY');
};
export var getCurrentDate = function () {
    var today = moment();
    return {
        day: today.format('dddd'),
        date: today.format('D'),
        monthAndYear: today.format('MMMM YYYY'),
    };
};
export var getGreeting = function () {
    var myDate = new Date();
    var hrs = myDate.getHours();
    if (hrs < 12)
        return 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
        return 'Good Afternoon';
    else
        return 'Good Evening';
};
export var getYear = function (date) {
    if (!date) {
        return 'Invalid';
    }
    return moment(date).format('YYYY');
};
// below exports are required for fuse-ai-school
export var getMonth = function (date) {
    if (!date) {
        return 'Invalid';
    }
    return moment(date).format('MMM, YYYY');
};
export var getWeek = function (date) {
    if (!date) {
        return 'Invalid';
    }
    return moment(date).format('ddd DD');
};
export var formatDate = function (date, format) {
    return moment(date).format(format || 'll');
};
export var getDate = function () {
    return moment();
};
export var isBeforeToday = function (date) {
    return moment(date).isBefore(moment());
};
export var isAfterToday = function (date) {
    return moment(date).isAfter(moment());
};
export var isSame = function (date, value) {
    return moment(date).isSame(moment(), value);
};
export var getStartTime = function (date) {
    return moment(date).from(moment());
};
export var getDifferenceAsMinutes = function (data) {
    return Math.round(moment.duration(moment(data).diff(moment())).asMinutes());
};
export var getDifferenceAsHours = function (data) {
    return Math.round(moment.duration(moment().diff(moment(data))).asHours());
};
export var isBeforeCurrentTime = function (date) {
    return moment(date).valueOf() < moment().valueOf();
};
export var unixIntoReadable = function (timestamp) {
    if (timestamp) {
        var d = new Date(timestamp);
        return d.toLocaleString('default', { month: 'long' }) + ' ' + d.getDate() + ', ' + d.getFullYear();
    }
    return '';
};
export var getMonthOptions = function () {
    return [
        { label: 'All', value: '-1' },
        { label: 'January', value: '1' },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];
};
export var getYearOptions = function (from, to) {
    return Array.from({ length: to - from + 1 }, function (v, k) {
        return {
            label: "" + (to - k),
            value: "" + (to - k),
        };
    });
};
export var getYearOptionsReverse = function (from, to) {
    return Array.from({ length: to - from + 1 }, function (v, k) {
        return {
            label: "" + (from + k),
            value: "" + (from + k),
        };
    });
};
export var getYesterdaysDate = function () {
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
};
